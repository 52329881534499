import React from "react";
import {Paper} from "@material-ui/core";
import EnhancedTable from "components/DataTable/EnhancedTable";
import {Assessment} from "@material-ui/icons";
import {useIntl} from "react-intl";
import moment from "moment";

export default function CourseList(props) {
    const intl = useIntl();
    const columns = [
        {title: intl.formatMessage({id:'courses.list.column.title', defaultMessage:'Title'}), field: 'title'},
        {title: intl.formatMessage({id:'courses.list.column.description', defaultMessage:'Description'}), field: 'description',
        render: row => {
            if (row.description) {
                let doc = new DOMParser().parseFromString(row.description, 'text/html');
                return doc.body.textContent.slice(0, 100) + '...' || "";
            }
            return "";
        }},
        {title: intl.formatMessage({id:'courses.list.column.days', defaultMessage:'Days'}), field: 'course_days_count'},
        {title: intl.formatMessage({id:'courses.list.column.teams', defaultMessage:'Teams'}), field: 'course_teams_count'},
        {title: intl.formatMessage({id:'courses.list.column.contact', defaultMessage:'Contact'}), field: 'contact_user.name'},
        {
            title: intl.formatMessage({id:'courses.list.column.created', defaultMessage:'Created'}),
            field: 'created_at',
            type: "datetime",
            render: row => row.created_at ? moment(row.created_at).format("YYYY-MM-DD HH:mm") : ""
        },
    ];

    return (
      <Paper>
        <EnhancedTable
          title={intl.formatMessage({id:'courses.list.heading', defaultMessage:'Courses'})}
          columns={columns}
          query="courses"
          mutations="course"
          fields='title description course_teams_count course_days_count contact_user{name} created_at'
          icon={<Assessment/>}
          {...props}
        />
      </Paper>
    );
}