import boxShadows from "assets/theme/box-shadow.js";

const componentStyles = (theme) => ({
    appBarRoot: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
        paddingLeft: "250px",
        // backgroundColor: theme.palette.background.default,
        background: "#fff",
        borderBottomColor: theme.palette.gray["400"],
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        color: theme.palette.text.primary,
    },
    toolbarRoot: {
        minHeight: 50,
        maxHeight: 50
    },
    brandTitle: {
        textTransform: "uppercase",
        margin: "0",
        color: theme.palette.adminNavbarSearch.main,
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    searchBox: {
        padding: ".25rem .25rem",
        borderColor: theme.palette.adminNavbarSearch.main,
        borderRadius: "2rem",
        border: "2px solid",
        backgroundColor: "initial",
        boxShadow: boxShadows.inputBoxShadow,
        transition: "box-shadow .15s ease",
    },
    searchIcon: {
        color: theme.palette.adminNavbarSearch.main,
        marginRight: "0.5rem",
        marginLeft: "1rem",
    },
    searchInput: {
        color: theme.palette.adminNavbarSearch.main,
        width: "270px",
        backgroundColor: "initial",
        border: 0,
        boxShadow: "none",
        padding: "0",
    },
    containerRoot: {
        [theme.breakpoints.up("md")]: {
            paddingLeft: "30px",
            paddingRight: "30px",
        },
    },
    title: {
        color: theme.palette.primary.main,
        fontWeight: 600
    },
    breadcrumb: {
        "&:hover": {
            cursor: "pointer"
        }
    }
});

export default componentStyles;
