import React from "react";
import {Paper} from "@material-ui/core";
import EnhancedTable from "components/DataTable/EnhancedTable";
import {Business} from "@material-ui/icons";
import {useIntl} from "react-intl";

export default function OrganisationList(props) {
    const intl = useIntl();
    const columns = [
        {title: intl.formatMessage({id:'organisations.list.column.name', defaultMessage:'Name'}), field: 'name'},
        {title: intl.formatMessage({id:'organisations.list.column.city', defaultMessage:'City'}), field: 'city'},
        {title: intl.formatMessage({id:'organisations.list.column.telephone', defaultMessage:'Phone'}), field: 'telephone'},
        {title: intl.formatMessage({id:'organisations.list.column.email', defaultMessage:'Email'}), field: 'email'},
        {title: intl.formatMessage({id:'organisations.list.column.contact', defaultMessage:'Contact'}), field: 'contact_user.name'},
        {title: intl.formatMessage({id:'organisations.list.column.contact_phone', defaultMessage:'Contact phone'}), field: 'contact_user.phone'},
        {title: intl.formatMessage({id:'organisations.list.column.users', defaultMessage:'Users'}), field: 'users_count'},
    ];

    return (
      <Paper>
        <EnhancedTable
          title={intl.formatMessage({id:'organisations.list.table.heading', defaultMessage:"Organisations"})}
          columns={columns}
          query="organisations"
          mutations="organisation"
          fields='id name address_1 city telephone email contact_user{name phone} users_count'
          icon={<Business/>}
          {...props}
        />
      </Paper>
    );
}