import React, { useState, useCallback } from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Link,
  Paper,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  DialogTitle
} from "@material-ui/core";
import {useIntl} from "react-intl";
import {Description, Delete, Edit} from "@material-ui/icons";
import {graphQLApi} from "services/GraphQLApi";
import {useAuthDispatch} from "contexts/Auth";
import FileEdit from "./FileEdit";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: "20px 0"
  },
  fileContainer: {
    padding: "20px 10px",
    textAlign: "center",
    margin: 10,
    position: "relative",
    minWidth: 150
  },
  thumbnail: {
    width: 60,
    height: 40,
    objectFit: "contain",
    marginBottom: 10
  },
  name: {
    userSelect: "none"
  },
  actions: {
    position: "absolute",
    top: 5,
    right: 5,
    display: "flex",
    flexDirection: "column"
  },
  action: {
    marginBottom: 5,
    color: theme.palette.gray[400],
    "&:hover": {
      color: "#000"
    }
  },
  link: {
    color: theme.palette.text.secondary,
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
}));

export default function FileGrid(props) {
  const [selectedFile, setSelectedFile] = useState({});
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const classes = useStyles();

  const intl = useIntl();

  const client = new graphQLApi(useAuthDispatch(), props.history);
  const stableClient = useCallback(client, []);

  const showEditHandler = (file) => {
    setSelectedFile(file);
    setShowEditDialog(true);
  };

  const showDeleteHandler = (file) => {
    setSelectedFile(file);
    setShowDeleteAlert(true);
  };

  const editHandler = (file) => {
    setShowEditDialog(false);
    props.onEdit(file);
  };

  const deleteHandler = () => {
    setShowDeleteAlert(false);
    if (!selectedFile.id) return;
    stableClient.mutate(`($id:ID!) {mediaFileDelete(
      id:$id
    )}`, {
      id: selectedFile.id
    }).then(r => {
      if (!r) return console.log('Could not delete media file');
      props.onDelete(selectedFile);
    });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2} direction="row">
        {props.files.map((file, index) => (
          <Grid key={index} item xs={2}>
            <Paper className={classes.fileContainer}>
              <div className={classes.actions}>
                <IconButton onClick={() => showEditHandler(file)} size="small" className={classes.action} aria-label="edit">
                  <Edit />
                </IconButton>
                <IconButton onClick={() => showDeleteHandler(file)} size="small" className={classes.action} aria-label="delete">
                  <Delete />
                </IconButton>
              </div>
              <Link classes={{ root: classes.link }} href={file.file_uri} target="_blank" rel="noreferrer">
                {file.file_thumb ? <img className={classes.thumbnail} src={file.file_thumb} alt="" /> : <Description className={classes.thumbnail} />}
                <Typography className={classes.name} title={file.description}>{file.title}</Typography>
              </Link>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <FileEdit
        show={showEditDialog}
        file={selectedFile}
        onEdit={editHandler}
        onClose={() => setShowEditDialog(false)}
      />
      <Dialog open={showDeleteAlert} onClose={() => setShowDeleteAlert(false)}>
        <DialogTitle disableTypography><Typography variant="h2">{intl.formatMessage({id:"dropzone.list.confirm.delete.title", defaultMessage: "Delete file"})}</Typography></DialogTitle>
        <DialogContent style={{overflowY:"clip"}}>
          <DialogContentText>{intl.formatMessage({id: "dropzone.list.confirm.delete.message"})} {selectedFile.file}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteAlert(false)}>{intl.formatMessage({id:"common.button.back"})}</Button>
          <Button color="primary" onClick={deleteHandler}>{intl.formatMessage({id:"common.tooltip.delete"})}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}