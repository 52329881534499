import React, { useReducer, createContext, useContext } from "react";

var AuthStateContext = createContext();
var AuthDispatchContext = createContext();

const LOCAL_STORAGE_KEY = "sfsdf234e234h2kjefbwbf";

const ACTION_LOGIN_SUCCESS = "LOGIN_SUCCESS";
const ACTION_LOGIN_FAILURE = "LOGIN_FAILURE";
const ACTION_REFRESH_SUCCESS = "REFRESH_SUCCESS";
const ACTION_REFRESH_FAILED = "REFRESH_FAILED";
const ACTION_REGISTER_SUCCESS = "REGISTER_SUCCESS";
const ACTION_REGISTER_FAILURE = "REGISTER_FAILURE";
const ACTION_SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";
const ACTION_TOKEN_NOT_FOUND = "TOKEN_NOT_FOUND";

function getApiDomain() {
  const baseDomain = "app.opendims.com";
  if (window.location.hostname === baseDomain) {
    return "https://" + baseDomain + "/api";
  }
  if (
    /^.+\.app\.openpim\.dk$/.test(window.location.hostname) ||
    /^.+\.app\.opendims\.com$/.test(window.location.hostname) ||
    process.env.REACT_APP_API_URL === undefined ||
    process.env.REACT_APP_API_URL === null ||
    process.env.REACT_APP_API_URL === ""
  ) {
    return "https://" +
      window.location.hostname
        .replace(/\.app\.opendims\.com$/, "")
        .replace(/\.app\.openpim\.dk$/, "")
        .replace(/\./g, "_") +
      "." + baseDomain + "/api";
  }
  return process.env.REACT_APP_API_URL;
}

function authReducer(state, action) {
  //console.log('User action', action);
  switch (action.type) {
    case ACTION_LOGIN_SUCCESS:
    case ACTION_REFRESH_SUCCESS:
    case ACTION_REGISTER_SUCCESS:
      return { ...state, isAuthenticated: true };
    case ACTION_LOGIN_FAILURE:
    case ACTION_REFRESH_FAILED:
    case ACTION_SIGN_OUT_SUCCESS:
    case ACTION_TOKEN_NOT_FOUND:
    case ACTION_REGISTER_FAILURE:
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function AuthProvider({ children }) {
  var [state, dispatch] = useReducer(authReducer, {
    isAuthenticated: !!localStorage.getItem(LOCAL_STORAGE_KEY),
  });

  authRefresh();

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
}

function useAuthState() {
  var context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }
  return context;
}

function useAuthDispatch() {
  var context = useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error("useAuthDispatch must be used within a AuthProvider");
  }
  return context;
}

function authHeader() {
  let jwt = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  if (!jwt) {
    return null;
  }
  return { Authorization: jwt.token_type + " " + jwt.token };
}

const authCallbacks = [];
function callbackOnUserUpdate() {
  let user = authUser();
  //console.debug('Sending user update to callbacks', user);
  authCallbacks.forEach((callback) => {
    callback(user);
  });
}
function registerAuthCallbackOnUserUpdate(callback) {
  authCallbacks.push(callback);
}
function authUser() {
  let jwt = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  let user = { id: null, roles: [], name: "Guest" };
  if (jwt && jwt.token) {
    user = JSON.parse(atob(jwt.token.split(".")[1]));
  }
  user.hasRole = (role) => {
    if (Array.isArray(role)) {
      let found = false;
      for (let i = 0; i < role.length; i++) {
        if (user.roles.find((ur) => ur.key === role[i]) !== undefined) {
          found = true;
          break;
        }
      }
      return found;
    }
    return user.roles.find((r) => r.key === role) !== undefined;
  };
  return user;
}
function authRoles(hasRole = "") {
  if (hasRole !== "") {
    return authUser().roles.indexOf(hasRole) !== -1;
  }
  return authUser().roles;
}
function authUpdateToken(header) {
  let data = String(header);
  if (data.indexOf("Bearer") === -1) {
    return null;
  }
  let jwt = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  jwt.token = data.substr(7);
  storeJwToken(jwt);
  return true;
}
function storeJwToken(json) {
  json.expires_at = Date.now() + json.expires_in * 1000;
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(json));
  callbackOnUserUpdate();
  //console.debug('Storing updated token, expires at', (new Date(json.expires_at)).toLocaleString());
  return json;
}

function authRefresh(forced = false) {
  let jwt = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  //console.log('Checking JWT', jwt, authUser());
  if (!jwt) {
    if (window.location.href.indexOf("/admin") !== -1) {
      window.location.href = "/login";
    }
    return false;
  }

  if (!forced && jwt.expires_at - 5000 > Date.now()) {
    setRefreshTimer(jwt.expires_at - Date.now());
    return;
  }

  let user = authUser();
  if (!user) {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    window.location.href = "/login";
    return;
  }
  if (window.apiWorking) {
    //console.debug('API is working, so postpone token refresh for 500ms');
    setTimeout(() => authRefresh(forced), 500);
    return;
  }
  //console.debug('Sending token refresh request', (new Date()).toLocaleString());
  let header = authHeader();
  localStorage.removeItem(LOCAL_STORAGE_KEY);
  fetch(getApiDomain() + "/auth/refresh/" + user.id, {
    method: "GET",
    cache: "no-cache",
    mode: "cors",
    headers: header,
  })
    .then(function (response) {
      if (response.status < 200 || response.status >= 300) {
        window.location.href = "/login";
        Promise.reject("Wrong response code " + response.status);
      }
      return response.json();
    })
    .then((data) => {
      if (!data.token) {
        return;
      }
      data = storeJwToken(data);
      setRefreshTimer(data.expires_in * 1000);
      //console.info('Token has been updated, next refresh at', (new Date(data.expires_at)).toLocaleString());
    });
}

let authRefreshTimer = null;
function setRefreshTimer(timeout) {
  timeout -= 2000; // Make sure we refresh before the token expires
  if (authRefreshTimer) {
    clearTimeout(authRefreshTimer);
  }
  //console.debug('Starting timer to refresh token at', (new Date(Date.now()+timeout)).toLocaleString());
  authRefreshTimer = setTimeout(authRefresh, timeout);
}

function authSignOut(dispatch) {
  localStorage.removeItem(LOCAL_STORAGE_KEY);
  dispatch({ type: ACTION_SIGN_OUT_SUCCESS });
}

function authLogin(
  dispatch,
  history,
  setIsLoading,
  login,
  password,
  setValidation = null
) {
  setIsLoading(true);

  var formData = new FormData();
  formData.append("email", login);
  formData.append("password", password);
  fetch(getApiDomain() + "/auth/login", {
    method: "POST",
    cache: "no-cache",
    mode: "cors",
    body: formData,
  })
    .then((response) => {
      setIsLoading(false);
      return response.json().then((data) => ({ json: data, meta: response }));
    })
    .then(({ json, meta }) => {
      //console.log('Checking login response: ', meta);
      if (meta.status < 200 || meta.status >= 300) {
        if (setValidation) setValidation(json);
        return;
      }
      json = storeJwToken(json);
      setRefreshTimer((json.expires_in - 10) * 1000);
      dispatch({ type: ACTION_LOGIN_SUCCESS });
      history.push("/admin/home");
    })
    .catch(() => {
      if (setValidation)
        setValidation({
          message:
            "The authentication server failed to respond, please try again later.",
        });
      setIsLoading(false);
    });
}

function authRegister(
  dispatch,
  history,
  setIsLoading,
  name,
  login,
  password,
  passwordConfirm,
  setValidation = null
) {
  setIsLoading(true);

  var formData = new FormData();
  formData.append("name", name);
  formData.append("email", login);
  formData.append("password", password);
  formData.append("password_confirmation", passwordConfirm);
  fetch(getApiDomain() + "/auth/register", {
    method: "POST",
    cache: "no-cache",
    mode: "cors",
    body: formData,
  })
    .then((response) => {
      setIsLoading(false);
      return response.json().then((data) => ({ json: data, meta: response }));
    })
    .then(({ json, meta }) => {
      if (meta.status < 200 || meta.status >= 300) {
        if (setValidation) setValidation(json);
      } else {
        history.push("/login");
      }
    })
    .catch(() => {
      setIsLoading(false);
    });
}

export {
  AuthProvider,
  useAuthState,
  useAuthDispatch,
  authLogin,
  authSignOut,
  authRegister,
  authHeader,
  authRoles,
  authUser,
  authRefresh,
  registerAuthCallbackOnUserUpdate,
  authUpdateToken,
  getApiDomain,
};
