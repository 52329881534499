import React from "react";
import {Paper} from "@material-ui/core";
import EnhancedTable from "components/DataTable/EnhancedTable";
import {FormatListNumbered} from "@material-ui/icons";
import {useIntl} from "react-intl";

export default function CourseLevelList(props) {
    const intl = useIntl();
    const columns = [
        {title: intl.formatMessage({id:'course_levels.list.column.title', defaultMessage:'Title'}), field: 'title'},
        {
            title: intl.formatMessage({id: 'course_levels.list.column.description', defaultMessage: 'Description'}),
            field: 'description',
            render: row => {
                if (row.description) {
                    let doc = new DOMParser().parseFromString(row.description, 'text/html');
                    return doc.body.textContent.slice(0, 50) + '...' || "";
                }
                return "";
            }
        },
        {title: intl.formatMessage({id:'course_levels.list.column.level', defaultMessage:'Level'}), field: 'level', type: 'numeric'},
        {title: intl.formatMessage({id:'course_levels.list.column.elements', defaultMessage:'Dashboard Elements'}), field: 'course_level_elements_count', type: 'numeric'},
    ];

    return (
      <Paper>
        <EnhancedTable
          title={intl.formatMessage({id:'course_levels.list.table.heading', defaultMessage:"Course Levels list"})}
          columns={columns}
          query="courseLevels"
          mutations="courseLevel"
          fields='id level title description course_level_elements_count'
          icon={<FormatListNumbered/>}
          {...props}
        />
      </Paper>
    );
}