import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Box from "@material-ui/core/Box";
import React from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import componentStyles from "assets/theme/components/admin-footer.js";

const useStyles = makeStyles(componentStyles);

export default function (props) {
    const classes = useStyles();
    return <Grid container classes={{ root: classes.justifyContentCenter }}>
        <Box
            item
            xs={12}
            xl={6}
            component={Grid}
            display="flex"
            alignItems="center"
            className={classes.justifyContentCenter}
        >
            <div className={classes.copyrightWrapper}>
                © {new Date().getFullYear()}{" "}
                <a
                    className={classes.copyrightLink}
                    href="https://relatuz.dk"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    Dansk Center for ICDP
                </a>
            </div>
        </Box>

        <Grid
            item
            xl={6}
            component={Box}
            display="flex"
            style={{justifyContent:"flex-end"}}
        >
            <Box
                component={List}
                display="flex"
                justifyContent="center"
                alignItems="center"
                className={classes.flexDirectionColumn}
            >
                <ListItem
                    component="a"
                    href="https://relatuz.dk"
                    rel="noopener noreferrer"
                    target="_blank"
                    classes={{
                        root: classes.listItemRoot,
                    }}
                >
                    Dansk Center for ICDP
                </ListItem>

                <ListItem
                    component="a"
                    href={process.env.REACT_APP_URL}
                    rel="noopener noreferrer"
                    target="_blank"
                    classes={{
                        root: classes.listItemRoot,
                    }}
                >
                    {process.env.REACT_APP_NAME}
                </ListItem>
            </Box>
        </Grid>
    </Grid>;
}