import React, {useRef} from "react";
import {useIntl} from "react-intl";
import {Button, Dialog, DialogActions, DialogContent} from "@material-ui/core";
import EnhancedTable from "../../../components/DataTable/EnhancedTable";
import {Add, GroupAdd} from "@material-ui/icons";

export default function UserSelectDialog (props) {
  const intl = useIntl();
  const tableRef = useRef();

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="md">
      <DialogContent style={{overflowY:"clip"}}>
        <EnhancedTable
          icon={<GroupAdd/>}
          defaultRowsPerPage={10}
          ref={tableRef}
          title={intl.formatMessage({id: "user.edit.select_dialog.title", defaultMessage: "Select user to add"})}
          query="users"
          filter={props.filter && props.role ? `ids_not:[${props.filter.map(u => u.id).join(",")}], role: "${props.role}"` : undefined}
          fields="id name email"
          columns={[
            {title: intl.formatMessage({id: 'team.edit.list.column.name', defaultMessage: 'Name'}), field: 'name'},
            {title: intl.formatMessage({id: 'team.edit.list.column.email', defaultMessage: 'Email'}), field: 'email'}
          ]}
          actions={[
            {
              tooltip: intl.formatMessage({id:"common.button.add", defaultMessage:"Add"}), 
              icon: Add, 
              rowClick: true, 
              onClick: row => {
                props.onActionAdd(row);
                tableRef.current.update();
              }}
          ]}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={props.onClose}>{intl.formatMessage({id:"common.button.close"})}</Button>
      </DialogActions>
    </Dialog>
  );
}