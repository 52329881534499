import React, {useCallback, useEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {
  AppBar,
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@material-ui/core";
import PropTypes from "prop-types";
import {
  Add,
  AssignmentLateOutlined,
  AssignmentTurnedIn,
  Delete,
  Edit,
  Event,
  HighlightOff,
  Home
} from "@material-ui/icons";
import "ckeditor5-custom-build/build/translations/da";
import "ckeditor5-custom-build/build/translations/en";
import {graphQLApi, graphQLReduceFields} from "services/GraphQLApi";
import {useAuthDispatch} from "contexts/Auth";
import EditForm from "components/Form/EditForm";
import MaterialSelectTable from "../Materials/MaterialSelectTable";
import EnhancedTable from "../../../components/DataTable/EnhancedTable";
import DayDialog from "./DayDialog";
import ConfirmDialog from "../../../components/Dialogs/ConfirmDialog";

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <Card
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </Card>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export function CourseEdit(props) {
  const intl = useIntl();

  let id = Number(props.match.params.id);

  /**
   * Notification
   */
  const [notification, setNotification] = React.useState({
    severity: 'info',
    show: false,
    message: '',
  });
  const notify = (message, color = 'info') => {
    setNotification({severity: color, message: message, show: true});
  }
  const closeNotification = () => setNotification({...notification, show: false});

  /**
   * Tabs
   */
  const [tab, setTab] = React.useState(0);
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  /**
   * API
   */
  const [course, setCourse] = useState({
    title: '',
    description: '',
    course_level_id: null,
    contact_user_id: null,
    media_folder_id: null,
    star_moments_count: 8,
    has_focus_points: false,
    has_video_and_analysis: false,
    has_implementation_activities: false,
    materials_count: 0,
    materials: [],
    course_days_count: 0,
    course_days: [],
    course_teams_count: 0,
    course_teams: [],
    course_evaluation_questions_count: 0,
    course_evaluation_questions: [],
    user_notes_count: 0,
    user_notes: [],
    created_at: null,
    updated_at: null
  });

  const generalFields = [
    {
      field: "title",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "courses.edit.label.title", defaultMessage: "Title"}),
      input: "text"
    },
    {
      field: "description",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "courses.edit.label.description", defaultMessage: "Description"}),
      input: "html",
      editor: null,
    },
    {
      column: 2,
      field: "contact_user_id",
      initial: null,
      type: "ID",
      query: "users",
      filter: 'role:"superuser"',
      titleField: "name",
      label: intl.formatMessage({id: "courses.edit.label.contact_user", defaultMessage: "Contact user"}),
    },
    {
      column: 2,
      label: intl.formatMessage({id: "courses.edit.heading.options", defaultMessage: "Course options"}),
      input: "heading"
    },
    {
      column: 2,
      field: "course_level_id",
      initial: null,
      type: "ID",
      query: "courseLevels",
      titleField: "title",
      label: intl.formatMessage({id: "courses.edit.label.level", defaultMessage: "Level"}),
    },
    {
      column: 2,
      field: "media_folder_id",
      initial: null,
      type: "ID",
      query: "mediaFolders",
      titleField: "title",
      filter: "parent_id:null",
      label: intl.formatMessage({id: "courses.edit.label.media", defaultMessage: "Media"}),
    },
    {
      column: 2,
      field: "star_moments_count",
      initial: 8,
      type: "Int",
      label: intl.formatMessage({id: "courses.edit.label.star_moments", defaultMessage: "Star moments"}),
      input: "number"
    },
    {
      column: 2,
      field: "has_focus_points",
      initial: false,
      type: "Bool",
      label: intl.formatMessage({id: "courses.edit.label.has_focus_points", defaultMessage: "Focus points"}),
      input: "switch"
    },
    {
      column: 2,
      field: "has_video_and_analysis",
      initial: false,
      type: "Bool",
      label: intl.formatMessage({id: "courses.edit.label.has_video_and_analysis", defaultMessage: "Video and analysis"}),
      input: "switch"
    },
    {
      column: 2,
      field: "has_implementation_activities",
      initial: false,
      type: "Bool",
      label: intl.formatMessage({
        id: "courses.edit.label.has_implementation_activities",
        defaultMessage: "Implementation activities"
      }),
      input: "switch"
    },
  ];
  const initialValidation = graphQLReduceFields(generalFields, 'validation');
  const [validation, setValidation] = useState(initialValidation);
  const setValidationFromErrors = (errors) => {
    if (Array.isArray(errors) && errors[0] && errors[0].hasOwnProperty('extensions') && errors[0].extensions.hasOwnProperty('validation')) {
      setValidation({...initialValidation, ...errors[0].extensions.validation});
    }
  };
  const client = new graphQLApi(useAuthDispatch(), props.history, null, {handleErrors: setValidationFromErrors});
  const stableClient = useCallback(client, []);
  useEffect(() => {
    if (id) {
      stableClient.query('{' +
        'courses' + (id ? '(filter:{id:' + id + '})' : '') +
        '{data{' +
        'id title description course_level{id title} contact_user{id name} media_folder{id title} materials{id title description type file file_uri} star_moments_count has_focus_points has_implementation_activities has_video_and_analysis ' +
        'course_evaluation_questions{id title description type} ' +
        'course_days{id title description sorting} ' +
        '}}' +
        '}').then(result => {
        if (result && result.hasOwnProperty('courses')) {
          let c = result.courses.data[0];
          c = {
            ...c,
            course_level_id: c.course_level,
            contact_user_id: c.contact_user,
            media_folder_id: c.media_folder,
          }
          setCourse(c);
          if (generalFields[1].editor) {
            generalFields[1].editor.setData(c.description ? c.description : "");
          }
        }
      });
    }
  }, [id, stableClient]);

  /**
   * General tab
   */
  const generalSave = (data) => {
    let variables = {
      title: "String",
      description: "String",
      course_level_id: "ID",
      contact_user_id: "ID",
      media_folder_id: "ID",
      star_moments_count: "Int",
      has_focus_points: "Boolean",
      has_video_and_analysis: "Boolean",
      has_implementation_activities: "Boolean",
      materials: "[ID]",
    };
    if (id) {
      data.id = id;
      variables.id = "ID!";
    }
    client.mutation('course', variables, data, 'id').then(result => {
      if (result && result.response) {
        notify(intl.formatMessage({id: "courses.edit.saved", defaultMessage: "Course was saved"}), "succes");
        if (isNaN(id)) {
          props.history.replace(props.history.location.pathname.replace("create", result.response.id));
        }
      }
    });
  };

  /**
   * Evaluation questions
   */
  const [deletedQuestions, setDeletedQuestions] = useState([]);
  const handleChangeQuestion = (idx, property, value) => {
    setCourse(cur => {
      let questions = [...cur.course_evaluation_questions];
      questions[idx][property] = value;
      return {
        ...cur, course_evaluation_questions: questions
      }
    });
  };
  const evaluationsSave = () => {
    let variables = {};
    let data = {};
    let promises = [];
    let deletions = [];
    for (let i in course.course_evaluation_questions) {
      data = {...course.course_evaluation_questions[i], course_id: {id: id}};
      variables = {
        title: "String",
        description: "String",
        type: "String",
        course_id: "ID",
      };
      if (data.id) {
        variables.id = "ID!";
      }
      promises[i] = client.mutation('courseEvaluationQuestion', variables, data, 'id');
    }
    for (let i in deletedQuestions) {
      if (deletedQuestions[i].id) {
        deletions[i] = client.mutate('{deleted: courseEvaluationQuestionDelete(id:' + deletedQuestions[i].id + ')}');
      }
    }
    Promise.all([...promises, ...deletions]).then(r => {
      if (r && r.length) {
        let questions = [...course.course_evaluation_questions];
        // As long as we hande the update/create promises first it is ok to use the indexes directly against the
        // course.course_evaluation_questions array, they will match
        for (let i in r) {
          if (r[i].response && questions[i]) {
            questions[i].id = r[i].response.id;
          }
        }
        setDeletedQuestions([]);
        setCourse(cur => {
          return {...cur, course_evaluation_questions: questions}
        });
        notify(intl.formatMessage({
          id: "courses.edit.evaluation_saved",
          defaultMessage: "Course evaluation questions was saved"
        }), "success");
      }
    });
  };

  /**
   * Days tab
   */
  const daysTableRef = useRef();
  const daysColumns = [
    {title: intl.formatMessage({id: 'courses.edit.days.column.number', defaultMessage: 'Number'}), field: 'sorting'},
    {title: intl.formatMessage({id: 'courses.edit.days.column.title', defaultMessage: 'Title'}), field: 'title'},
    {
      title: intl.formatMessage({id: 'courses.edit.days.column.description', defaultMessage: 'Description'}),
      field: 'description',
      render: row => {
        if (row.description) {
          let doc = new DOMParser().parseFromString(row.description, 'text/html');
          return doc.body.textContent.slice(0, 100) + '...';
        }
        return "";
      }
    },
    {
      title: intl.formatMessage({id: 'courses.edit.days.column.materials_count', defaultMessage: 'Materials'}),
      field: 'materials_count'
    },
  ];
  const [day, setDay] = useState({});
  const [dayDialogOpen, setDayDialogOpen] = useState(false);
  const [confirm, setConfirm] = useState({
    open: false,
    title: "",
    message: "",
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          message={notification.message}
          style={{backgroundColor: notification.severity}}
          open={notification.show}
          onClose={closeNotification}
          autoHideDuration={6000}
        />
        <AppBar position="static" color="default" style={{marginBottom: "1rem"}}>
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label={intl.formatMessage({id: "course.edit.tabs.general", defaultMessage: "General"})}
                 icon={<Home/>} {...a11yProps(0)} />
            <Tab label={intl.formatMessage({id: "course.edit.tabs.days", defaultMessage: "Days"})}
                 icon={<Event/>} {...a11yProps(1)}
                 disabled={isNaN(id)}/>
            <Tab label={intl.formatMessage({id: "course.edit.tabs.evaluation", defaultMessage: "Evaluations"})}
                 icon={course.course_evaluation_questions.length ? <AssignmentTurnedIn/> : <AssignmentLateOutlined/>} {...a11yProps(2)}
                 disabled={isNaN(id)}/>
          </Tabs>
        </AppBar>
        <TabPanel value={tab} index={0}>
          <Grid container>
            <Grid item xs={12}>
              <EditForm
                data={course}
                setData={setCourse}
                isLoading={false}
                save={generalSave}
                validation={validation}
                fields={generalFields}
                colSizes={[8, 4]}
                extraComponent={<MaterialSelectTable
                  materials={course.materials}
                  onAdd={row => setCourse(cur => {
                    return {...cur, materials: [...cur.materials, row]}
                  })}
                  onRemove={row => setCourse(cur => {
                    return {...cur, materials: cur.materials.filter(c => c.id !== row.id)}
                  })}
                />}
                history={props.history}
              />
            </Grid>
          </Grid>
        </TabPanel>{/* General */}
        <TabPanel value={tab} index={1}>{/* Days */}
          <EnhancedTable
            ref={daysTableRef}
            searchable={false}
            title={intl.formatMessage({id:"course.edit.course_days.heading", defaultMessage:"Course days and their materials"})}
            query={'courseDays'}
            filter={"course_id:" + id}
            sorting={"sorting"}
            fields="id title description sorting materials_count materials{id title description type file}"
            columns={daysColumns}
            icon={<Event/>}
            actions={[
              {
                tooltip: intl.formatMessage({id: "common.button.add", defaultMessage: "Add"}),
                icon: Add,
                isFreeAction: true,
                onClick: e => {
                  setDay({course_id: id, title: "", description: "", sorting: course.course_days_count + 1, materials: []});
                  setDayDialogOpen(true);
                }
              },
              {
                tooltip: intl.formatMessage({id: "common.button.edit", defaultMessage: "Edit"}),
                icon: Edit,
                rowClick: true,
                onClick: row => {
                  setDay({course_id: id, ...row});
                  setDayDialogOpen(true);
                }
              },
              {
                tooltip: intl.formatMessage({id: "common.button.delete", defaultMessage: "Delete"}),
                icon: Delete,
                onClick: row => {
                  setDay(row);
                  setConfirm({
                    open: true,
                    title: intl.formatMessage({id:"courses.edit.days.confirm_delete.title", defaultMessage:"Delete course day"}),
                    message: intl.formatMessage({id:"courses.edit.days.confirm_delete.message", defaultMessage:"Are you sure you want to delete this course day?"}),
                  })
                }
              },
            ]}
          />
          <ConfirmDialog onClose={r => {
            setConfirm({...confirm, open:false});
            if (r) {
              client.mutate("{courseDayDelete(id:"+day.id+")}").then(r => {
                if (r) {
                  notify(intl.formatMessage({id:"courses.edit.days.deleted", defaultMessage:"Course day was deleted!"}), 'success');
                  daysTableRef.current.update();
                }
              });
            }
          }} open={confirm.open} title={confirm.title} message={confirm.message}/>
          <DayDialog
            day={day}
            setDay={setDay}
            open={dayDialogOpen}
            onClose={e => {
              setDayDialogOpen(null);
              daysTableRef.current.update();
            }}
            history={props.history}
          />
        </TabPanel>
        <TabPanel value={tab} index={2}>{/* Evaluation */}
          <Grid container spacing={4}>
            {(course.course_evaluation_questions && course.course_evaluation_questions.length) ? course.course_evaluation_questions.map((q, idx) =>
                <Grid item xs={12} key={"course_evaluation_questions-" + idx}>
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        label={intl.formatMessage({id: "courses.edit.label.title", defaultMessage: "Title"})}
                        value={q.title}
                        onChange={e => handleChangeQuestion(idx, 'title', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <InputLabel shrink htmlFor="age-native-label-placeholder">
                          {intl.formatMessage({id: "courses.edit.label.type", defaultMessage: "Type"})}
                        </InputLabel>
                        <Select
                          value={q.type}
                          onChange={e => handleChangeQuestion(idx, 'type', e.target.value)}
                        >
                          <MenuItem value="text">{intl.formatMessage({
                            id: "courses.edit.label.type.text",
                            defaultMessage: "Text"
                          })}</MenuItem>
                          <MenuItem value="yes/no">{intl.formatMessage({
                            id: "courses.edit.label.type.yes_no",
                            defaultMessage: "Yes/No"
                          })}</MenuItem>
                          <MenuItem value="8">{intl.formatMessage({
                            id: "courses.edit.label.type.score_8",
                            defaultMessage: "Score with max 8"
                          })}</MenuItem>
                          <MenuItem value="9">{intl.formatMessage({
                            id: "courses.edit.label.type.score_9",
                            defaultMessage: "Score with max 9"
                          })}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={1} style={{textAlign: "right"}}>
                      <IconButton title={intl.formatMessage({
                        id: "courses.edit.evaluation.question_remove",
                        defaultMessage: "Remove question from evaluation"
                      })} color="primary" onClick={e => setCourse(cur => {
                        let questions = [...cur.course_evaluation_questions];
                        setDeletedQuestions([...deletedQuestions, questions.splice(idx, 1)[0]]);
                        return {
                          ...cur, course_evaluation_questions: questions
                        }
                      })}><HighlightOff/></IconButton>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label={intl.formatMessage({id: "courses.edit.label.description", defaultMessage: "Description"})}
                        value={q.description}
                        onChange={e => handleChangeQuestion(idx, 'description', e.target.value)}
                        multiline
                        rows={4}
                      />
                    </Grid>
                  </Grid>
                </Grid>) :
              <Grid item xs={12} style={{textAlign: "center"}}>
                <Typography variant="h4">There are no evaluation questions on this course</Typography>
              </Grid>
            }
            <Grid item xs={12}>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item>
                  <Button
                    color="primary"
                    onClick={e => setCourse(cur => {
                      return {
                        ...cur, course_evaluation_questions: [...cur.course_evaluation_questions, {
                          title: "",
                          description: "",
                          type: "text",
                        }]
                      }
                    })}
                  >{intl.formatMessage({
                    id: "courses.edit.button.question_add",
                    defaultMessage: "Add a question"
                  })}</Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={evaluationsSave}
                  >{intl.formatMessage({
                    id: "courses.edit.button.save_questions",
                    defaultMessage: "Save questions"
                  })}</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>{/* Evaluation */}
      </Grid>
    </Grid>
  );
}