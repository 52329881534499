import React, {useState, useEffect, useCallback} from "react";
import {useIntl} from "react-intl";
import {Dialog, DialogContent} from "@material-ui/core";
import EditForm from "components/Form/EditForm";
import { graphQLReduceFields } from "services/GraphQLApi";
import {graphQLApi} from "services/GraphQLApi";
import {useAuthDispatch} from "contexts/Auth";
import moment from "moment";
import MaterialSelectTable from "../Materials/MaterialSelectTable";

export default function EditDayDialog (props) {
  const [courseDay, setCourseDay] = useState();

  const { day, notify } = props;

  const intl = useIntl();

  const generalFields = [
    {
      column: 1,
      field: "location",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "team.edit.days.location", defaultMessage: "Location"}),
      input: "text"
    },
    {
      column: 1,
      field: "address",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "team.edit.days.address", defaultMessage: "Address"})
    },
    {
      column: 1,
      field: "city",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "team.edit.days.city", defaultMessage: "City"}),
      input: "text"
    },
    {
      column: 1,
      field: "zip",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "team.edit.days.zip", defaultMessage: "ZIP"})
    },
    {
      column: 2,
      field: "date",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "team.edit.days.date", defaultMessage: "Date"}),
      input: "date"
    },
    {
      column: 2,
      field: "start_time",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "team.edit.days.start_time", defaultMessage: "Start time"}),
      input: "time"
    },
    {
      column: 2,
      field: "finish_time",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "team.edit.days.finish_time", defaultMessage: "Finish time"}),
      input: "time"
    },
  ];

  const initialValidation = graphQLReduceFields(generalFields, 'validation');
  const [validation, setValidation] = useState(initialValidation);
  const setValidationFromErrors = (errors) => {
    if (Array.isArray(errors) && errors[0] && errors[0].hasOwnProperty('extensions') && errors[0].extensions.hasOwnProperty('validation')) {
      setValidation({...initialValidation, ...errors[0].extensions.validation});
    }
  };
  const client = new graphQLApi(useAuthDispatch(), props.history, null, {handleErrors: setValidationFromErrors});
  const stableClient = useCallback(client, []);

  useEffect(() => {
    if (!day || !day.id) return null;
    const transformedDay = {
      ...day,
      location: day.location !== null ? day.location : "",
      address: day.address !== null ? day.address : "",
      city: day.city !== null ? day.city : "",
      zip: day.zip !== null ? day.zip : "",
      date: moment(day.start_at ? day.start_at : undefined).format('yyyy-MM-DD'),
      start_time: day.start_at ? moment(day.start_at).format('HH:mm') : "09:00",
      finish_time: day.finish_at ? moment(day.finish_at).format('HH:mm') : "15:15",
    };
    setValidation(initialValidation);
    setCourseDay(transformedDay);
  }, [day]);

  const generalSave = (data) => {
    let variables = {
      id: "ID!",
      start_at: "String",
      finish_at: "String",
      location: "String",
      address: "String",
      city: "String",
      zip: "String",
      materials: "[ID]",
    };
    console.log('Line:109', data.date, 'date');
    console.dir(data.date);
    if (data.date === null || data.date === "" || data.date === "0000-00-00") {
      setValidation(v => {
        let valids = {...v};
        valids.date = [intl.formatMessage({id:"team.edit.day.date_is_required",defaultMessage:"The date is required for a team day."})];
        return valids;
      });
      return;
    }
    data.start_at = data.date + ' ' + data.start_time;
    data.finish_at = data.date + ' ' + data.finish_time;
    stableClient.mutation('courseTeamDay', variables, data, 'id').then(result => {
      if (result && result.response) {
        notify(intl.formatMessage({id:"team.edit.day.saved", defaultMessage:"Team day was saved"}), "succes");
        props.onUpdate(data);
      }
      props.onClose();
    });
  };

  if (!courseDay) return null;

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="md">
      <DialogContent style={{overflowY:"clip"}}>
        <EditForm
          data={courseDay}
          setData={setCourseDay}
          isLoading={false}
          save={generalSave}
          validation={validation}
          fields={generalFields}
          colSizes={[8,4]}
          extraComponent={<MaterialSelectTable
            materials={courseDay.materials}
            onAdd={row => setCourseDay(cur => {
              return {...cur, materials: [...cur.materials, row]}
            })}
            onRemove={row => setCourseDay(cur => {
              return {...cur, materials: cur.materials.filter(c => c.id !== row.id)}
            })}
          />}
        />
      </DialogContent>
    </Dialog>
  );
}