import React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import EditDayDialog from "./EditDayDialog";
import {Edit} from "@material-ui/icons";
import moment from "moment";

export default function DayTable(props) {
  const intl = useIntl();
  const [openEditDayDialog, setOpenEditDayDialog] = React.useState(false);
  const [selectedDay, setSelectedDay] = React.useState({});

  return (
    <TableContainer>
      <h3>
        <FormattedMessage id="team.edit.courseTeamDays.days" defaultMessage="Team days" />
      </h3>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell><FormattedMessage id="team.edit.list.column.day" defaultMessage="Day"/></TableCell>
            <TableCell><FormattedMessage id="team.edit.list.column.title" defaultMessage="Title"/></TableCell>
            <TableCell><FormattedMessage id="team.edit.list.column.date" defaultMessage="Date"/></TableCell>
            <TableCell><FormattedMessage id="team.edit.list.column.start_time" defaultMessage="Starts"/></TableCell>
            <TableCell><FormattedMessage id="team.edit.list.column.finish_time" defaultMessage="Finishes"/></TableCell>
            <TableCell><FormattedMessage id="team.edit.list.column.location" defaultMessage="Location"/></TableCell>
            <TableCell><FormattedMessage id="team.edit.list.column.address" defaultMessage="Address"/></TableCell>
            <TableCell><FormattedMessage id="team.edit.list.column.city" defaultMessage="City"/></TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{props.days.map(d => {
          return (<TableRow key={"users-select-row-" + d.id} onClick={e => {setSelectedDay(d); setOpenEditDayDialog(true);}} style={{cursor:"pointer"}}>
            <TableCell>{d.course_day.sorting}</TableCell>
            <TableCell>{d.course_day.title}</TableCell>
            <TableCell>{d.start_at ? moment(d.start_at).format(intl.formatMessage({id:"common.date.format"})) : '-'}</TableCell>
            <TableCell>{d.start_at ? moment(d.start_at).format(intl.formatMessage({id:"common.time.format"})) : '-'}</TableCell>
            <TableCell>{d.finish_at ? moment(d.finish_at).format(intl.formatMessage({id:"common.time.format"})) : '-'}</TableCell>
            <TableCell>{d.location}</TableCell>
            <TableCell>{d.address}</TableCell>
            <TableCell>{d.city}</TableCell>
            <TableCell align="right"><IconButton size="small" onClick={e => {setSelectedDay(d); setOpenEditDayDialog(true);}}>
              <Edit style={{height: 20, width: 20}}/>
            </IconButton></TableCell>
          </TableRow>)
        })}</TableBody>
      </Table>
      <EditDayDialog
        open={openEditDayDialog}
        history={props.history}
        day={selectedDay}
        onClose={e => setOpenEditDayDialog(false)}
        filter={props.users}
        onActionAdd={row => props.onAdd(row)}
        role={props.role}
        notify={props.notify}
        onUpdate={props.onUpdate}
      />
    </TableContainer>
  )
}