import React from "react";
import {Paper} from "@material-ui/core";
import EnhancedTable from "components/DataTable/EnhancedTable";
import {GroupWork} from "@material-ui/icons";
import {useIntl} from "react-intl";

export default function ImplementationActivityList(props) {
    const intl = useIntl();
    const columns = [
        {title: intl.formatMessage({id:'implementation_activities.list.column.title', defaultMessage:'Title'}), field: 'title'},
        {title: intl.formatMessage({id:'implementation_activities.list.column.description', defaultMessage:'Description'}), field: 'description'},
        {title: intl.formatMessage({id:'implementation_activities.list.column.sorting', defaultMessage:'Sorting'}), field: 'sorting', type: 'numeric'},
        {title: intl.formatMessage({id:'implementation_activities.list.column.file', defaultMessage:'Schema file'}), field: 'file'}
    ];

    return (
      <Paper>
        <EnhancedTable
          title={intl.formatMessage({id:'implementation_activities.list.table.heading', defaultMessage:"Implementation Activities list"})}
          columns={columns}
          query="implementationActivities"
          mutations="implementationActivity"
          fields='id title description sorting file'
          icon={<GroupWork/>}
          {...props}
        />
      </Paper>
    );
}