import React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import UserSelectDialog from "./UserSelectDialog";
import {RemoveCircle} from "@material-ui/icons";

export default function UserSelectTable(props) {
  const intl = useIntl();
  const [openUsersDialog, setOpenUsersDialog] = React.useState(false);

  const getUserRoleTitle = (role) => {
    switch (role) {
      case 'admin':
        return intl.formatMessage({id: "role.admin", defaultMessage: "administrator"});
      case 'superuser':
        return intl.formatMessage({id: "role.superuser", defaultMessage: "super-user"});
      case 'trainer':
        return intl.formatMessage({id: "role.trainer", defaultMessage: "trainer"});
      case 'organisers':
        return intl.formatMessage({id: "role.organiser", defaultMessage: "organiser"});
      case 'participant':
        return intl.formatMessage({id: "role.participant", defaultMessage: "participant"});
    }
  }

  return (
    <TableContainer>
      <h3>
        {props.title}
        <Link style={{fontSize: "smaller", cursor: "pointer", marginLeft: 25, color: "primary"}}
              onClick={e => setOpenUsersDialog(true)}>
          {props.role ?
            <FormattedMessage id="team.edit.list.add_user_of_role" defaultMessage="+ Add {user_of_role} to team"
                              values={{user_of_role: getUserRoleTitle(props.role)}}/>:
          <FormattedMessage id="team.edit.list.add_user" defaultMessage="+ Add user to team"/>}
        </Link>
      </h3>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell><FormattedMessage id="team.edit.list.column.name" defaultMessage="Name"/></TableCell>
            <TableCell><FormattedMessage id="team.edit.list.column.email" defaultMessage="Email"/></TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{props.users.map(u => {
          return (<TableRow key={"users-select-row-" + u.id}>
            <TableCell>{u.name}</TableCell>
            <TableCell>{u.email}</TableCell>
            <TableCell align="right"><IconButton size="small" onClick={e => props.onRemove(u)}>
              <RemoveCircle style={{height: 20, width: 20}}/>
            </IconButton></TableCell>
          </TableRow>)
        })}</TableBody>
      </Table>
      <UserSelectDialog
        open={openUsersDialog}
        onClose={e => setOpenUsersDialog(false)}
        filter={props.users}
        onActionAdd={row => props.onAdd(row)}
        role={props.role}
      />
    </TableContainer>
  )
}