import React from "react";
import {Paper} from "@material-ui/core";
import EnhancedTable from "components/DataTable/EnhancedTable";
import {Description} from "@material-ui/icons";
import {useIntl} from "react-intl";
import {getMaterialTypes} from "variables/materials";

export default function MaterialList(props) {
    const intl = useIntl();
    const columns = [
        {title: intl.formatMessage({id:'materials.list.column.title', defaultMessage:'Title'}), field: 'title'},
        {
            title: intl.formatMessage({id:'materials.list.column.type', defaultMessage:'Type'}),
            field: 'type',
            render: (row) => {let type = getMaterialTypes(intl).find(v => v.id === row.type); return type ? type.name : '';},
        },
        {title: intl.formatMessage({id:'materials.list.column.file', defaultMessage:'File'}), field: 'file'}
    ];

    return (
      <Paper>
        <EnhancedTable
          title={intl.formatMessage({id:'materials.list.table.heading', defaultMessage:"Materials"})}
          columns={columns}
          query="materials"
          mutations="material"
          fields='id title type file'
          icon={<Description/>}
          {...props}
        />
      </Paper>
    );
}