import React from "react";
import {useIntl} from "react-intl";
import {Card, CardContent, CardHeader, Grid} from "@material-ui/core";
import {People} from "@material-ui/icons";
import GraphQLEditForm from "components/GraphQL/GraphQLEditForm";
import {authUser} from "../../../contexts/Auth";

export default function RoleEdit(props) {
    const intl = useIntl();

    let id = Number(props.match.params.id);

    const fields = [
        {
            field: "title",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "roles.edit.label.title", defaultMessage: "Title"}),
            input: "text"
        },
        {
            field: "priority",
            initial: 0,
            type: "Int",
            label: intl.formatMessage({id: "roles.edit.label.priority", defaultMessage: "Priority"}),
            input: "number",
            inputProps: {
                min: authUser().roles[0] ? authUser().roles[0].priority : 1,
                max: 255,
                step: 1,
            },
            disabled: function (data) {
                return !(authUser().hasRole(['sysdev', 'admin']) || data.priority > 50);
            }
        },
        {
            field: "description",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "roles.edit.label.description", defaultMessage: "Description"}),
            input: "text"
        }
    ];

    return (
        <Grid container>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        avatar={<People color="primary"/>}
                        title={intl.formatMessage({id: "roles.edit.heading", defaultMessage:"Roles edit"})}
                        titleTypographyProps={{color:"primary"}}
                    />
                    <CardContent>
                        <GraphQLEditForm id={id}
                                         query={"roles"}
                                         mutations={"role"}
                                         fields={fields}
                                         cols={2}
                                         {...props}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
