import React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import {getMaterialTypes} from "variables/materials";
import MaterialSelectDialog from "./MaterialSelectDialog";
import {RemoveCircle} from "@material-ui/icons";

export default function MaterialSelectTable(props) {
  const intl = useIntl();
  const [openMaterialsDialog, setOpenMaterialsDialog] = React.useState(false);

  return (
    <TableContainer>
      <h3>
        <FormattedMessage id="materials.list.table.heading" defaultMessage="Materials list"/>
        <Link style={{fontSize:"smaller",cursor:"pointer",marginLeft:25,color:"primary"}} onClick={e => setOpenMaterialsDialog(true)}>
          <FormattedMessage id="materials.list.add_material" defaultMessage="+ Add material to course"/>
        </Link>
      </h3>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell><FormattedMessage id="materials.list.column.title" defaultMessage="Title"/></TableCell>
            <TableCell><FormattedMessage id="materials.list.column.description" defaultMessage="Description"/></TableCell>
            <TableCell><FormattedMessage id="materials.list.column.type" defaultMessage="Type"/></TableCell>
            <TableCell><FormattedMessage id="materials.list.column.file" defaultMessage="File"/></TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{props.materials.map(m => {
          let type = getMaterialTypes(intl).find(v => v.id === m.type);
          return (<TableRow key={"materials-select-row-" + m.id}>
            <TableCell>{m.title}</TableCell>
            <TableCell>{m.description ? m.description.slice(0, 75) + '...' : ''}</TableCell>
            <TableCell>{type ? type.name : ''}</TableCell>
            <TableCell>{m.file}</TableCell>
            <TableCell align="right"><IconButton size="small" onClick={e => props.onRemove(m)}>
              <RemoveCircle style={{height: 20, width: 20}}/>
            </IconButton></TableCell>
          </TableRow>)
        })}</TableBody>
      </Table>
      <MaterialSelectDialog
        open={openMaterialsDialog}
        onClose={e => setOpenMaterialsDialog(false)}
        filter={props.materials}
        onActionAdd={row => props.onAdd(row)}
      />
    </TableContainer>
  )
}