import React from "react";
import {IntlProvider} from "react-intl";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {ThemeProvider} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "assets/theme/theme";
import AdminLayout from "layouts/Admin";
import AuthLayout from "layouts/Auth";
import {useAuthState} from "contexts/Auth";
import {LocaleContext} from "contexts/Intl";
import locale_en from "translations/en.json";
import locale_da from "translations/da.json";

export default function (props) {
    let { isAuthenticated } = useAuthState();
    let [locale] = React.useContext(LocaleContext);
    const translations = {
        en: locale_en,
        da: locale_da,
    };

    return <IntlProvider
        locale={locale}
        defaultLocale="en"
        messages={translations[locale]}
    >
        <ThemeProvider theme={theme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline/>
            <BrowserRouter>
                <Switch>
                    {isAuthenticated ?
                    <Route path="/admin" render={(props) => <AdminLayout {...props} />}/>
                    :
                    <Route path="/" render={(props) => <AuthLayout {...props} />}/>
                    }
                    <Redirect from="/" to={isAuthenticated ? "/admin/products" : "/login"}/>
                </Switch>
            </BrowserRouter>
        </ThemeProvider>
    </IntlProvider>;
}