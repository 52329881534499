import React from "react";
import {useIntl} from "react-intl";
import EditForm from "../../../components/Form/EditForm";
import {graphQLReduceFields} from "../../../services/GraphQLApi";
import {Card, CardContent, CardHeader} from "@material-ui/core";


export default function LevelElementComponent(props) {
    const {element, setElement} = props;
    const intl = useIntl();

    const generalFields = [
        {
            field: "title",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "course_level_elements.edit.label.title", defaultMessage: "Title"}),
            input: "text",
            required: true,
        },
        {
            field: "link",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "course_level_elements.edit.label.link", defaultMessage: "Link"}),
            input: "text"
        },
        {
            column: 1,
            field: "image",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "course_level_elements.edit.label.file", defaultMessage: "File"}),
            input: "file",
            accepts: "*",
            required: true,
        },

    ];

    return (
        <Card>
            <CardHeader
              title={intl.formatMessage({id:"course_level_element.title", defaultMessage:"Element {number}"}, {number: element.sorting})}
            />
            <CardContent>
            <EditForm
                data={element}
                setData={setElement}
                isLoading={false}
                fields={generalFields}
                history={props.history}
                cols={1}
                validation={graphQLReduceFields(generalFields, 'validation')}
            />
            </CardContent>
        </Card>
    )
}
