import React, {useRef} from "react";
import {useIntl} from "react-intl";
import {Button, Dialog, DialogActions, DialogContent} from "@material-ui/core";
import EnhancedTable from "../../../components/DataTable/EnhancedTable";
import {getMaterialTypes} from "../../../variables/materials";
import {Add, Book} from "@material-ui/icons";

export default function (props) {
  const intl = useIntl();
  const tableRef = useRef();

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="md">
      <DialogContent style={{overflowY:"clip"}}>
        <EnhancedTable
          icon={<Book/>}
          defaultRowsPerPage={10}
          ref={tableRef}
          title={intl.formatMessage({id: "materials.select_dialog.title", defaultMessage: "Select material to add"})}
          query="materials"
          fields="id title description type file"
          filter={props.filter ? "ids_not:[" + props.filter.map(m => m.id).join(",") + "]" : undefined}
          columns={[
            {title: intl.formatMessage({id: 'materials.list.column.title', defaultMessage: 'Title'}), field: 'title'},
            {
              title: intl.formatMessage({id: 'materials.list.column.type', defaultMessage: 'Type'}),
              field: 'type',
              render: (row) => {let type = getMaterialTypes(intl).find(v => v.id === row.type); return type ? type.name : '';},
            },
            {title: intl.formatMessage({id: 'materials.list.column.file', defaultMessage: 'File'}), field: 'file'}
          ]}
          actions={[
            {tooltip: intl.formatMessage({id:"common.button.add", defaultMessage:"Add"}), icon: Add, rowClick: true, onClick: row => {
                props.onActionAdd(row);
                tableRef.current.update();
              }}
          ]}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={props.onClose}>{intl.formatMessage({id:"common.button.close"})}</Button>
      </DialogActions>
    </Dialog>
  );
}