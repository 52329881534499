
/*
    api/app/Models/Material.php:33
    const TYPE_GUIDE = 'guide';
    const TYPE_THEME = 'theme';
    const TYPE_THEORY = 'theory';
    const TYPE_PRESENTATION = 'presentation';
    const TYPE_QUESTIONS = 'questions';
 */
export function getMaterialTypes(intl) {
  return [
    {id: "theme", name: intl.formatMessage({id:"material.type.them", defaultMessage:"Theme"})},
    {id: "guide", name: intl.formatMessage({id:"material.type.guide", defaultMessage:"Guide"})},
    {id: "theory", name: intl.formatMessage({id:"material.type.theory", defaultMessage:"Theory"})},
    {id: "presentation", name: intl.formatMessage({id:"material.type.presentation", defaultMessage:"Presentation"})},
    {id: "questions", name: intl.formatMessage({id:"material.type.questions", defaultMessage:"Questions"})},
  ];
}
