import React from "react";
import {useIntl} from "react-intl";
import {Grid, Card, CardHeader, CardContent} from "@material-ui/core";
import {MenuBook} from "@material-ui/icons";
import GraphQLEditForm from "components/GraphQL/GraphQLEditForm";

export default function ConceptEdit(props) {
    const intl = useIntl();

    let id = Number(props.match.params.id);

    const fields = [
        {
            field: "name",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "concepts.edit.label.name", defaultMessage: "Name"}),
            input: "text"
        },
        {
            field: "description",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "concepts.edit.label.description", defaultMessage: "Description"}),
            input: "text"
        },
        {
            field: "file",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "concepts.edit.label.file", defaultMessage: "File"}),
            input: "file",
            accepts: "*",
        },
        {
            field: "link",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "concepts.edit.label.link", defaultMessage: "Link"}),
            input: "text"
        }
    ];

    return (
        <Grid container>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        avatar={<MenuBook color="primary"/>}
                        title={intl.formatMessage({id: "concepts.edit.heading", defaultMessage:"Concepts edit"})}
                        titleTypographyProps={{color:"primary"}}
                    />
                    <CardContent>
                        <GraphQLEditForm id={id}
                                         query={"concepts"}
                                         mutations={"concept"}
                                         fields={fields}
                                         cols={2}
                                         {...props}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
