import React from "react";
import {Paper} from "@material-ui/core";
import EnhancedTable from "components/DataTable/EnhancedTable";
import {People} from "@material-ui/icons";
import {useIntl} from "react-intl";
import moment from "moment";

export default function CourseTeamList(props) {
  const intl = useIntl();
  const columns = [
    {
      title: intl.formatMessage({id: 'course_teams.list.column.course', defaultMessage: 'Course'}),
      field: 'course.title'
    },
    {title: intl.formatMessage({id: 'course_teams.list.column.title', defaultMessage: 'Title'}), field: 'title'},
    {
      title: intl.formatMessage({id: 'course_teams.list.column.description', defaultMessage: 'Description'}),
      field: 'description',
      render: row => {
        if (row.description) {
          let doc = new DOMParser().parseFromString(row.description, 'text/html');
          return doc.body.textContent.slice(0, 50) + '...' || "";
        }
        return "";
      }
    },
    {
      title: intl.formatMessage({id: "course_teams.list.column.start_at", defaultMessage: "Start"}),
      field: "start_at",
      type: "datetime",
      render: row => row.start_at ? moment(row.start_at).format(intl.formatMessage({id: "common.date.format"})) : ""
    },
    {
      title: intl.formatMessage({id: "course_teams.list.column.finish_at", defaultMessage: "End"}),
      field: "end_at",
      type: "datetime",
      render: row => row.finish_at ? moment(row.finish_at).format(intl.formatMessage({id: "common.date.format"})) : ""
    },
    {
      title: intl.formatMessage({id: 'course_teams.list.column.participants', defaultMessage: 'Participants'}),
      field: 'course_team_participants_count'
    },
    {
      title: intl.formatMessage({id: 'course_teams.list.column.trainer', defaultMessage: 'Trainers'}),
      render: row => (row.course_team_trainers ? row.course_team_trainers.map(t => t.user.name).join(", ") : "")
    },
    {
      title: intl.formatMessage({id: 'course_teams.list.column.organisation', defaultMessage: 'Organisation'}),
      field: 'organisation.name'
    },
  ];

  return (
    <Paper>
      <EnhancedTable
        title={intl.formatMessage({id: 'course_teams.list.table.heading', defaultMessage: "Course teams"})}
        columns={columns}
        query="courseTeams"
        mutations="courseTeam"
        fields='id course{title} title course_team_trainers{user{name}} course_team_participants_count organisation{name} start_at finish_at'
        icon={<People/>}
        {...props}
      />
    </Paper>
  );
}