import React, {useEffect, useImperativeHandle} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow
} from "@material-ui/core";
import EnhancedTableHead from "./EnhancedTableHead";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import {makeStyles} from "@material-ui/core/styles";
import {graphQLApi} from "services/GraphQLApi";
import {useAuthDispatch} from "contexts/Auth";
import {Add, Check, Delete, Edit, ExpandLess, ExpandMore, Remove} from "@material-ui/icons";
import {FormattedMessage, useIntl} from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const EnhancedTable = React.forwardRef((props, ref) => {
  const {
    columns,
    actions = [],
    query,
    fields,
    filter,
    sorting = '',
    direction = 'asc',
    children,
    mutations = null,
    history,
    title,
    icon,
    defaultRowsPerPage = 25,
    searchable = true,
  } = props;

  const classes = useStyles();
  const intl = useIntl();
  const authDispatch = useAuthDispatch();

  const [isLoading, setIsLoading] = React.useState(true);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [deleteRow, setDeleteRow] = React.useState(null);
  const [order, setOrder] = React.useState(direction);
  const [orderBy, setOrderBy] = React.useState(sorting);
  const [search, setSearch] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const [loadRows, setLoadRows] = React.useState(true);
  const [rows, setRows] = React.useState([]);
  const [expandedRows, setExpandedRows] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage);

  const dispatch = useAuthDispatch();
  useEffect(() => {
    if (loadRows === true) {
      setLoadRows(false);
      const client = new graphQLApi(dispatch, props.history);
      setIsLoading(true);
      client.query('{' + query +
        '(' +
        'page:' + (page + 1) + ',limit:' + rowsPerPage + (orderBy !== '' ? ',sorting:"' + orderBy + '",direction:"' + order + '"' : '') +
        ',filter:{' + (search !== '' ? 'q:"' + search + '",' : '') + (filter ? filter + ',' : '') + (children ? 'parent_id:null,' : '') + '}' +
        ')' +
        '{total current_page last_page from to data {id ' + fields + (children ? ' parent{id} children{id}' : '') + '} } }').then(r => {
        setIsLoading(false);
        if (r) {
          setRows(r[query].data);
          setTotal(r[query].total);
        }
      });
    }
  }, [loadRows]);

  const getChildrenOfRow = (row) => {
    const client = new graphQLApi(dispatch, props.history);
    setIsLoading(true);
    client.query('{' + query +
      '(' + (orderBy !== '' ? ',sorting:"' + orderBy + '",direction:"' + order + '"' : '') +
      'filter:{parent_id:' + row.id + '}' +
      ')' +
      '{total current_page last_page from to data {id parent{id} children{id} ' + fields + '} } }').then(r => {
      setIsLoading(false);
      if (r) {
        setRows(curRows => {
          let parentRowIndex = curRows.findIndex(cr => cr.id === row.id);
          for (let i = r[query].data.length - 1; i > -1; i--) {
            curRows.splice(parentRowIndex + 1, 0, {
              ...r[query].data[i],
              path: (row.path ? row.path + '_' : '') + row.id
            });
          }
          // console.log('Inserted rows', r[query].data, 'at position', parentRowIndex, curRows);
          return curRows;
        });
        let path = row.path ? row.path + "_" + row.id : row.id;
        setExpandedRows([...expandedRows, path]);
        if (row.children && selected.find(sr => sr === row.id)) {
          console.log("Selecting the children of the row becasue it is selected", row.id, row.children)
          setSelected([...selected, ...row.children.map(c => c.id)]);
        }
      }
    });
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setLoadRows(true);
  };

  const handleSearchChange = (newSearch) => {
    setPage(0);
    setSearch(newSearch);
    setLoadRows(true);
  }

  const handleSelectAllClick = (event) => {
    event.stopPropagation();
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleSelectClick = (event, row, alreadySelected) => {
    let id = row.id;
    if (event) {
      event.stopPropagation();
    }
    let newSelected = [];
    let forceSelect = false;
    if (alreadySelected) {
      forceSelect = true;
    } else {
      alreadySelected = [...selected];
    }
    const selectedIndex = alreadySelected.indexOf(id);

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(alreadySelected, id);
    } else if (!forceSelect && selectedIndex === 0) {
      newSelected = newSelected.concat(alreadySelected.slice(1));
    } else if (!forceSelect && selectedIndex === alreadySelected.length - 1) {
      newSelected = newSelected.concat(alreadySelected.slice(0, -1));
    } else if (!forceSelect && selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    } else {
      newSelected = alreadySelected;
    }

    if (row.children && selectedIndex === -1) {
      row.children.forEach(c => {
        let child = rows.find(r => r.id === c.id);
        if (child) {
          newSelected = handleSelectClick(null, child, newSelected);
        } else {
          newSelected.push(c.id);
        }
      })
    }

    if (event) {
      setSelected(newSelected);
    } else {
      return newSelected;
    }
  };

  const handleChangePage = (event, newPage) => {
    event.stopPropagation();
    setPage(newPage);
    setLoadRows(true);
  };

  const handleChangeRowsPerPage = (event) => {
    event.stopPropagation();
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setLoadRows(true);
  };

  const handleSelectedActionClick = (event, action) => {
    event.stopPropagation();
    action(rows.filter(rf => selected.indexOf(rf.id) !== -1));
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const hasOnSelectedActions = () => actions && actions.filter(af => af.onSelected === true).length > 0;

  const toggleRowExpand = (row) => {
    let path = row.path ? row.path + "_" + row.id : row.id;
    let index = expandedRows.findIndex(cr => cr === path);
    if (index !== -1) {
      setExpandedRows([...expandedRows].filter(cr => cr.substr(0, path.length) !== path));
      return;
    }
    if (rows.filter(r => r.parent && r.parent.id === row.id).length === 0) {
      getChildrenOfRow(row);
    } else {
      let expRows = [...expandedRows];
      expRows.push(path);
      setExpandedRows(expRows);
    }
  };

  const getChildLevel = (row, level = 0) => {
    if (row && row.parent) {
      return getChildLevel(rows.find(r => r.id === row.parent.id), ++level);
    }
    return level;
  }

  const getTreeButtonPadding = (row) => {
    if (!children) {
      return '';
    }
    let expandButton = <span>&nbsp;</span>;
    if (row.children && row.children.length) {
      expandButton = <IconButton style={{display: "inline-flex"}} size={"small"} onClick={event => {
        event.stopPropagation();
        toggleRowExpand(row)
      }}>{
        expandedRows.findIndex(er => er === (row.path ? row.path + "_" : "") + row.id) === -1 ? <ExpandMore/> :
          <ExpandLess/>
      }</IconButton>;
    }
    return <Box display={"inline-block"} textAlign={"right"}
                width={(getChildLevel(row, 1) * 16) + 12}>{expandButton}</Box>;
  }

  useImperativeHandle(ref, () => ({
    update() {
      setLoadRows(true);
    },
    getRows() {
      return rows;
    },
    getSelected() {
      return selected;
    },
    setSelected(newSelected) {
      setSelected(newSelected);
    },
    clearSelected() {
      setSelected([]);
    },
  }));

  if (mutations && actions.length !== 3 && (actions.length === 0 || actions[actions.length-1].icon !== Delete)) {
    actions.push({
      rowClick: true,
      icon: Edit,
      tooltip: intl.formatMessage({id: "enhanced_table.actions.edit", defaultMessage: "Edit"}),
      onClick: (row) => {
        props.history.push(props.history.location.pathname + '/' + row.id)
      },
    });
    actions.push({
      icon: Add,
      isFreeAction: true,
      tooltip: intl.formatMessage({id: "enhanced_table.actions.add", defaultMessage: "Add"}),
      onClick: () => {
        props.history.push(props.history.location.pathname + '/create')
      },
    });
    actions.push({
      icon: Delete,
      tooltip: intl.formatMessage({id: "enhanced_table.actions.delete", defaultMessage: "Delete"}),
      onClick: (row) => {
        setDeleteRow(row);
        setConfirmDelete(true);
      },
    });
  }

  const handleConfirmDeletion = (confirmation) => {
    if (confirmation && deleteRow) {
      const client = new graphQLApi(authDispatch, history);
      client.mutate('{' + mutations + 'Delete(id:' + deleteRow.id + ')}').then(result => {
        if (result) {
          setRows([...rows.filter(r => r.id !== deleteRow.id)])
        }
      });
    }
    setConfirmDelete(false);
  }

  const renderRowCellData = (column, row) => {
    let field = column.field.split('.');
    let value = row;
    field.forEach(o => {
      if (value[o]) {
        value = value[o];
      }
      else {
        value = '';
      }
    })
    switch (column.type) {
      case 'bool':
      case 'boolean':
        return value ? <Check/> : <Remove/>
      default:
        return value;
    }
  }

  let rowAction = actions.find(a => a.rowClick);

  return (
    <div>
      <EnhancedTableToolbar
        numSelected={selected.length}
        icon={icon}
        title={title}
        actions={actions}
        onSelectedActions={handleSelectedActionClick}
        setSelected={setSelected}
        onSearchChange={searchable ? handleSearchChange : null}
      />
      <Snackbar open={isLoading} anchorOrigin={{vertical: "top", horizontal: "center"}} message={"Loading..."}
                action={<CircularProgress color="inherit"/>}/>
      <Dialog open={confirmDelete} onClose={() => setConfirmDelete(false)}>
        <DialogContent><FormattedMessage id={"enhanced_table.dialog.confirm_deletion"}
                                         defaultMessage={'Please confirm the deletion of "{row}"'}
                                         values={{row: deleteRow ? deleteRow[columns[0].field] : ''}}
        /></DialogContent>
        <DialogActions>
          <Button color="default" onClick={() => handleConfirmDeletion(false)}>Cancel</Button>
          <Button color="primary" onClick={() => handleConfirmDeletion(true)}>OK</Button>
        </DialogActions>
      </Dialog>
      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          size='small'
          aria-label="enhanced table"
          key={"table-with-expanded-" + expandedRows.join("-")}
        >
          <EnhancedTableHead
            columns={columns}
            actions={actions}
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            children={children}
          />
          <TableBody>{rows.length ? rows.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              if (children && row.path && expandedRows.findIndex(er => er === row.path) === -1) {
                // console.log('parent is not expanded', row.id, 'parent', row.path)
                return null;
              }
              // console.log('Returning row', row.id, 'parent is expanded or none', (row.parent && row.path))
              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={"row-" + index + "-" + row.id}
                  selected={isItemSelected}
                >
                  {hasOnSelectedActions() &&
                    <TableCell padding="checkbox">
                      <Checkbox
                        onClick={(event) => handleSelectClick(event, row)}
                        checked={isItemSelected}
                      />
                    </TableCell>
                  }
                  {columns.map((header, columnIndex) =>
                    <TableCell
                      onClick={event => {
                        if (rowAction) {
                          rowAction.onClick(row, event);
                        }
                      }}
                      style={{
                        cursor: rowAction ? "pointer" : "inherit",
                        textAlign: header.align ? header.align : "left",
                      }}
                      key={"row-cell-" + index + "-" + columnIndex + "-" + header.field}
                      align={header.align ? header.align : 'left'}
                    >
                      {columnIndex === 0 && getTreeButtonPadding(row)}
                      {header.render ? header.render(row) : renderRowCellData(header, row)}
                    </TableCell>
                  )}
                  {actions ?
                    <TableCell key={"row-cell-" + index + "-actions"} align="right">
                      {actions.filter(a => !a.toolbar && !a.onSelected && !a.isFreeAction).map((action, actionIndex) =>
                        <IconButton
                          size="small"
                          key={'row-' + index + '-actions-' + actionIndex}
                          title={action.tooltip}
                          onClick={event => {
                            event.stopPropagation();
                            action.onClick(row, event);
                          }}>
                          <action.icon style={{height: 20, width: 20}}/>
                        </IconButton>)}
                    </TableCell>
                    : ''
                  }
                </TableRow>);
            }) :
            <TableRow>
              <TableCell colSpan={columns.length + 2} align="center" style={{padding: 30}}>
                {intl.formatMessage({
                  id: "enhanced_table.no_data",
                  defaultMessage: "Sorry, there is nothing to show you here!"
                })}
              </TableCell>
            </TableRow>}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
});

export function EnhancedTableActionType() {
  return PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.object.isRequired,
      tooltip: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      toolbar: PropTypes.bool,
      onSelected: PropTypes.bool,
      isFreeAction: PropTypes.bool,
      rowClick: PropTypes.bool,
    })
  );
}

export function EnhancedTableColumnType() {
  return PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      sortable: PropTypes.bool,
      width: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['auto', 'inherit', 'default'])]),
      render: PropTypes.func,
      align: PropTypes.oneOf(['left', 'right']),
    })
  ).isRequired;
}

EnhancedTable.propTypes = {
  columns: EnhancedTableColumnType(),
  query: PropTypes.string.isRequired,
  fields: PropTypes.string.isRequired,
  filter: PropTypes.string,
  sorting: PropTypes.string,
  direction: PropTypes.oneOf(["asc", "desc"]),
  actions: EnhancedTableActionType(),
  children: PropTypes.bool,
  mutations: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
};

export default EnhancedTable;

