import React from "react";
import {Paper} from "@material-ui/core";
import EnhancedTable from "components/DataTable/EnhancedTable";
import {PermMedia} from "@material-ui/icons";
import {useIntl} from "react-intl";

export default function MediaFolderList(props) {
    const intl = useIntl();
    const columns = [
        {title: intl.formatMessage({id:'media_folders.list.column.title', defaultMessage:'Title'}), field: 'title'},
        {title: intl.formatMessage({id:'media_folders.list.column.description', defaultMessage:'Description'}), field: 'description'},
        {title: intl.formatMessage({id:'media_folders.list.column.files', defaultMessage:'Files'}), field: 'media_files_count'},
    ];

    return (
      <Paper>
        <EnhancedTable
          title={intl.formatMessage({id:'media_folders.list.table.heading', defaultMessage:"Media"})}
          columns={columns}
          query="mediaFolders"
          mutations="mediaFolder"
          fields='id title description media_files_count'
          icon={<PermMedia/>}
          children
          {...props}
        />
      </Paper>
    );
}