import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {Button, Dialog, DialogContent, DialogContentText, Grid} from "@material-ui/core";
import EditForm from "../../../components/Form/EditForm";
import {graphQLApi, graphQLReduceFields} from "../../../services/GraphQLApi";
import {makeStyles} from "@material-ui/core/styles";
import MaterialSelectTable from "../Materials/MaterialSelectTable";
import {useAuthDispatch} from "../../../contexts/Auth";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    paper: {
      overflowY: "hidden",
    }
  },
}));

export default function DayDialog(props) {
  const intl = useIntl();
  const classes = useStyles();

  const fields = [
    {
      column: 1,
      field: "sorting",
      initial: "1",
      type: "String",
      label: intl.formatMessage({id: "courses.edit.day.sorting", defaultMessage: "Number"}),
      input: "number",
      inputProps: {
        min: 1,
        max: 50,
        step: 1,
      },
    },
    {
      column: 2,
      field: "title",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "courses.edit.day.title", defaultMessage: "Title"}),
      input: "text"
    },
    {
      field: "description",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "courses.edit.day.description", defaultMessage: "Description"}),
      input: "html",
      editor: null,
      fullWidth: true,
    },
  ];
  const initialValidation = graphQLReduceFields(fields, 'validation');
  const [validation, setValidation] = useState(initialValidation);
  const setValidationFromErrors = (errors) => {
    if (Array.isArray(errors) && errors[0] && errors[0].hasOwnProperty('extensions') && errors[0].extensions.hasOwnProperty('validation')) {
      setValidation({...initialValidation, ...errors[0].extensions.validation});
    }
  };
  const client = new graphQLApi(useAuthDispatch(), props.history, null, {handleErrors: setValidationFromErrors});
  useEffect(() => setValidation(initialValidation), [props.open]);

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="md" classes={classes.dialogContent}>
      <DialogContent>
        <DialogContentText>
          <Grid container>
            <Grid item xs={12}>
              <EditForm
                fields={fields}
                data={props.day}
                setData={props.setDay}
                isLoading={false}
                validation={validation}
                cols={2}
                colSizes={[2,10]}
                extraComponent={<MaterialSelectTable
                  materials={props.day.materials}
                  onAdd={row => props.setDay(cur => {
                    return {...cur, materials: [...cur.materials, row]}
                  })}
                  onRemove={row => props.setDay(cur => {
                    return {...cur, materials: cur.materials.filter(c => c.id !== row.id)}
                  })}
                />}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={props.onClose}
                  >{intl.formatMessage({id:"common.button.close"})}</Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={e => {
                      let variables = {
                        title: "String",
                        description: "String",
                        sorting: "Int",
                        course_id: "ID",
                        materials: "[ID]",
                      }
                      if (props.day.id) {
                        variables.id = "ID!";
                      }
                      client.mutation('courseDay', variables, props.day, "id").then(result => {
                        if (result) {
                          props.onClose();
                        }
                      });
                    }}
                  >{intl.formatMessage({id:"common.button.save"})}</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}