import React, {useState, useEffect, useCallback} from "react";
import {useIntl} from "react-intl";
import {
    Grid,
    Card,
    CardHeader,
    CardContent,
    Snackbar
} from "@material-ui/core";
import {PermMedia} from "@material-ui/icons";
import GraphQLEditForm from "components/GraphQL/GraphQLEditForm";
import FileGrid from "components/Files/FileGrid";
import DropzoneUploader from "components/Dropzone/DropzoneUploader";
import {graphQLApi} from "services/GraphQLApi";
import {useAuthDispatch} from "contexts/Auth";

export default function MediaFolderEdit(props) {
    const [files, setFiles] = useState([]);

    const [notification, setNotification] = useState({
        severity: 'info',
        show: false,
        message: '',
    });
    const notify = (message, color = 'info') => {
    setNotification({severity: color, message: message, show: true});
    }
    const closeNotification = () => setNotification({...notification, show: false});

    const intl = useIntl();

    const id = Number(props.match.params.id);

    const client = new graphQLApi(useAuthDispatch(), props.history);
    const stableClient = useCallback(client, []);

    const fields = [
        {
            column: 1,
            field: "title",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "media_folders.edit.label.title", defaultMessage: "Title"}),
            input: "text"
        },
        {
            column: 2,
            field: "parent_id",
            initial: "",
            type: "ID",
            titleField: "title",
            query: "mediaFolders",
            filter: "parent_id:null",
            label: intl.formatMessage({id: "media_folders.edit.label.parent_folder", defaultMessage: "Parent folder"}),
        },
        {
            field: "description",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "media_folders.edit.label.description", defaultMessage: "Description"}),
            input: "text",
            fullWidth: true,
        }
    ];

    useEffect(() => {
        stableClient.query('{mediaFiles' + (id ? '(filter:{media_folder_id:'+id+'})' : '') +
        `{data{
          id
          title
          description
          file
          file_uri
          file_thumb
        }}` +
        '}').then(result => {
          if (result && result.hasOwnProperty('mediaFiles')) {
            setFiles(result.mediaFiles.data);
          }
      });
    }, [id]);

    const onEditHandler = (file) => {
        setFiles(files.map(f => f.id !== file.id ? f : {...f, ...file}));
    };

    const onDeleteHandler = (file) => {
        setFiles(files.filter(f => f.id !== file.id));
        notify(intl.formatMessage({id:"media.edit.fileDeleted", defaultMessage:"File was deleted"}), "succes");
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Snackbar
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                    }}
                    message={notification.message}
                    style={{backgroundColor: notification.severity}}
                    open={notification.show}
                    onClose={closeNotification}
                    autoHideDuration={6000}
                />
                <Card>
                    <CardHeader
                        avatar={<PermMedia color="primary"/>}
                        title={id ?
                          intl.formatMessage({id: "media_folders.edit.heading", defaultMessage:"Edit media folder"}) :
                          intl.formatMessage({id: "media_folders.create.heading", defaultMessage:"Create media folder"})
                        }
                        titleTypographyProps={{color:"primary"}}
                    />
                    <CardContent>
                        <GraphQLEditForm
                            id={id}
                            query={"mediaFolders"}
                            mutations={"mediaFolder"}
                            fields={fields}
                            cols={2}
                            redirectToEdit={true}
                            {...props}
                        />
                        {id ? <div>
                          <FileGrid files={files} onEdit={onEditHandler} onDelete={onDeleteHandler}/>
                          <DropzoneUploader
                            onUploaded={uploadedFiles => {
                                setFiles([...files, ...uploadedFiles]);
                            }}
                            mutation="MediaFile"
                            attributes={{media_folder_id: id}}
                          /></div> : ''}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
