import React from "react";
import {useIntl} from "react-intl";
import {Dialog, DialogContent} from "@material-ui/core";
import GraphQLEditForm from "components/GraphQL/GraphQLEditForm";

export default function EditFile (props) {
  const { file, onEdit } = props;

  const intl = useIntl();

  const fields = [
    {
      column: 1,
      field: "title",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "media_folders.edit.label.title", defaultMessage: "Title"}),
      input: "text"
    },
    {
      field: "description",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "media_folders.edit.label.description", defaultMessage: "Description"}),
      input: "text",
      fullWidth: true,
    }
  ];

  return (
    <Dialog open={props.show} onClose={props.onClose} maxWidth="md">
      <DialogContent style={{overflowY:"clip"}}>
        <GraphQLEditForm
          id={file.id}
          query={"mediaFiles"}
          mutations={"mediaFile"}
          fields={fields}
          cols={2}
          onSave={onEdit}
        />
      </DialogContent>
    </Dialog>
  );
}