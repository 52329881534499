import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Grid, IconButton, lighten, TextField, Toolbar, Tooltip, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {EnhancedTableActionType} from "./EnhancedTable";
import {Clear} from "@material-ui/icons";
import {useIntl} from "react-intl";

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        padding: "1.25rem 1.5rem",
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.text.secondary,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 200%',
        fontSize: 22
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const intl = useIntl();
    const {
        numSelected,
        onSearchChange,
        actions,
        onSelectedActions,
        title,
    } = props;

    const [search, setSearch] = useState('');

  let timeout = useRef();
  const debounce = (func, wait, immediate) => {
    return function () {
      let context = this, args = arguments;
      let later = function () {
        timeout.current = null;
        if (!immediate) func.apply(context, args);
      };
      let callNow = immediate && !timeout.current;
      // console.log('Debouncing', callNow, timeout.current, immediate, args);
      clearTimeout(timeout.current);
      timeout.current = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  const debouncedSetSearch = debounce((s) => {
    // console.log('Setting search', s);
    onSearchChange(s);
  }, 1250);

    const handleSearchChange = (event) => {
        //event.stopPropagation();
        setSearch(event.target.value);
        debouncedSetSearch(event.target.value);
    };

    return (
        <Toolbar
            className={classes.root + (numSelected > 0 ? ' ' + classes.highlight : '')}
        ><Typography className={classes.title} color="primary" variant="body2" component="div">
          <Grid container><Grid item style={{paddingTop:4,paddingRight:0}}>{props.icon}</Grid><Grid item>
            {numSelected > 0 ? (numSelected + " selected") : title}
          </Grid></Grid>
        </Typography>
            {(numSelected > 0 && actions) ? actions.filter(af => af.onSelected === true).map((action, index) =>
            <Tooltip key={"toolbar-action-" + index} title={action.tooltip}>
                <IconButton aria-label={action.tooltip} size="medium"
                            onClick={event => onSelectedActions(event, action.onClick)}>
                    <action.icon style={{height: 20, width: 20}}/>
                </IconButton>
            </Tooltip>
        ) : <Grid container spacing={1} justifyContent={"flex-end"} style={{margin:-11}}>
            {onSearchChange ?
                (<Grid item style={{flexGrow: 1}}><TextField
                    fullWidth
                    label={intl.formatMessage({id:"enhanced_table.toolbar.search", defaultMessage:"Search"})}
                    value={search}
                    onChange={handleSearchChange}
                    InputProps={{
                        endAdornment: search !== '' ? (
                            <IconButton onClick={() => {
                                timeout.current = null;
                                onSearchChange('');
                                setSearch('');
                            }}>
                                <Clear color="disabled" fontSize="small"/>
                            </IconButton>
                        ) : ''
                    }}
                /></Grid>) :
                ''}{
              actions.filter(af => af.isFreeAction).map((action, index) =>
                <Grid item key={"toolbar-free-action-" + index} style={{paddingTop:8}}><Tooltip title={action.tooltip}>
                  <IconButton aria-label={action.tooltip}
                              onClick={action.onClick} color="primary">
                    <action.icon/>
                  </IconButton>
                </Tooltip></Grid>)}
        </Grid>
        }
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onSearchChange: PropTypes.func,
    onSelectedActions: PropTypes.func.isRequired,
    actions: EnhancedTableActionType(),
    showTagFilterDialog: PropTypes.func,
    showFilterTags: PropTypes.func,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
};

export default EnhancedTableToolbar;
