import React from "react";
import {useIntl} from "react-intl";
import {Grid, Card, CardHeader, CardContent} from "@material-ui/core";
import {Business} from "@material-ui/icons";
import GraphQLEditForm from "components/GraphQL/GraphQLEditForm";

export default function OrganisationEdit(props) {
    const intl = useIntl();

    let id = Number(props.match.params.id);

    const fields = [
        {
            field: "name",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "organisations.edit.label.name", defaultMessage: "Name"}),
            input: "text"
        },
        {
            field: "address_1",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "organisations.edit.label.address_1", defaultMessage: "Address 1"}),
            input: "text"
        },
        {
            field: "address_2",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "organisations.edit.label.address_2", defaultMessage: "Address 2"}),
            input: "text"
        },
        {
            field: "city",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "organisations.edit.label.city", defaultMessage: "City"}),
            input: "text"
        },
        {
            field: "zip",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "organisations.edit.label.zip", defaultMessage: "Zip"}),
            input: "text"
        },
        {
            field: "country",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "organisations.edit.label.country", defaultMessage: "Country"}),
            input: "country"
        },
        {
            field: "telephone",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "organisations.edit.label.telephone", defaultMessage: "Telephone"}),
            input: "text"
        },
        {
            field: "email",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "organisations.edit.label.email", defaultMessage: "Email"}),
            input: "text"
        },
        {
            field: "email_invoice",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "organisations.edit.label.email_invoice", defaultMessage: "Email Invoice"}),
            input: "text"
        },
        {
            field: "ean",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "organisations.edit.label.ean", defaultMessage: "Ean"}),
            input: "text"
        },
        {
            field: "cvr",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "organisations.edit.label.cvr", defaultMessage: "Cvr"}),
            input: "text"
        }
    ];

    return (
        <Grid container>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        color="primary"
                        avatar={<Business color="primary"/>}
                        title={intl.formatMessage({id: "organisations.edit.heading", defaultMessage:"Organisations edit"})}
                        titleTypographyProps={{color:"primary"}}
                    />
                    <CardContent>
                        <GraphQLEditForm id={id}
                                         query={"organisations"}
                                         mutations={"organisation"}
                                         fields={fields}
                                         cols={2}
                                         {...props}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
