import React, {useCallback, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {
  AppBar,
  Box,
  Button,
  Card,
  Grid,
  Snackbar,
  Tab,
  Tabs,
} from "@material-ui/core";
import PropTypes from "prop-types";
import {Event, Home, People} from "@material-ui/icons";
import moment from 'moment';
import "ckeditor5-custom-build/build/translations/da";
import "ckeditor5-custom-build/build/translations/en";
import {graphQLApi} from "services/GraphQLApi";
import {useAuthDispatch} from "contexts/Auth";
import EditForm from "components/Form/EditForm";
import {graphQLReduceFields} from "services/GraphQLApi";
import UserSelectTable from './UserSelectTable';
import DayTable from './DayTable';
import MaterialSelectTable from "../Materials/MaterialSelectTable";

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <Card
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </Card>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default function CourseTeamEdit(props) {
  const intl = useIntl();

  let id = Number(props.match.params.id);

  /**
   * Notification
   */
  const [notification, setNotification] = React.useState({
    severity: 'info',
    show: false,
    message: '',
  });
  const notify = (message, color = 'info') => {
    setNotification({severity: color, message: message, show: true});
  }
  const closeNotification = () => setNotification({...notification, show: false});

  /**
   * Tabs
   */
  const [tab, setTab] = React.useState(0);
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  /**
   * API
   */
  const [team, setTeam] = useState({
    loaded: false,
    title: '',
    course_id: 0,
    start_at: moment(new Date()).format("yyyy-MM-DD"),
    finish_at: moment(new Date()).format("yyyy-MM-DD"),
    organisation_id: 0,
    organisers: [],
    trainers: [],
    participants: [],
    course_team_days: [],
    materials: [],
  });

  const generalFields = [
    {
      field: "title",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "team.edit.general.title", defaultMessage: "Title"}),
      input: "text"
    },
    {
      field: "course_id",
      initial: null,
      type: "ID",
      query: "courses",
      titleField: "title",
      label: intl.formatMessage({id: "team.edit.general.selectCourse", defaultMessage: "Select course"}),
      disabled: () => id > 0,
    },
    {
      field: "organisation_id",
      initial: null,
      type: "ID",
      query: "organisations",
      titleField: "name",
      label: intl.formatMessage({id: "team.edit.general.selectOrganisation", defaultMessage: "Select organisation"}),
    },
    {
      column: 2,
      field: "start_at",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "team.edit.general.startDate", defaultMessage: "Start date"}),
      input: "date"
    },
    {
      column: 2,
      field: "finish_at",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "team.edit.general.finishDate", defaultMessage: "Finish date"}),
      input: "date"
    },
  ];

  const initialValidation = graphQLReduceFields(generalFields, 'validation');
  const [validation, setValidation] = useState(initialValidation);
  const setValidationFromErrors = (errors) => {
    if (Array.isArray(errors) && errors[0] && errors[0].hasOwnProperty('extensions') && errors[0].extensions.hasOwnProperty('validation')) {
      setValidation({...initialValidation, ...errors[0].extensions.validation});
    }
  };
  const client = new graphQLApi(useAuthDispatch(), props.history, null, {handleErrors: setValidationFromErrors});
  const stableClient = useCallback(client, []);
  useEffect(() => {
    if (id) {
      stableClient.query('{courseTeams' + (id ? '(filter:{id:' + id + '})' : '') +
        `{data{
        id
        title
        course{id title}
        start_at
        finish_at
        organisation{id name}
        materials{id title description type file}
        course_team_organisers{id user{id name email}}
        course_team_trainers{id user{id name email}}
        course_team_participants{id user{id name email}}
        course_team_days{id course_day{id sorting title} start_at finish_at location address city zip materials{id title description type file}}
      }}` +
        '}').then(result => {
        if (result && result.hasOwnProperty('courseTeams')) {
          if (result.courseTeams.data[0].start_at) result.courseTeams.data[0].start_at = moment(result.courseTeams.data[0].start_at).format("yyyy-MM-DD");
          if (result.courseTeams.data[0].finish_at) result.courseTeams.data[0].finish_at = moment(result.courseTeams.data[0].finish_at).format("yyyy-MM-DD");
          result.courseTeams.data[0].course_id = result.courseTeams.data[0].course;
          result.courseTeams.data[0].organisation_id = result.courseTeams.data[0].organisation;
          result.courseTeams.data[0].organisers = result.courseTeams.data[0].course_team_organisers.map(u => u.user);
          result.courseTeams.data[0].trainers = result.courseTeams.data[0].course_team_trainers.map(u => u.user);
          result.courseTeams.data[0].participants = result.courseTeams.data[0].course_team_participants.map(u => u.user);
          setTeam(result.courseTeams.data[0]);
        }
      });
    }
  }, [id, stableClient]);

  const generalSave = (data) => {
    let variables = {
      title: "String",
      course_id: "ID",
      start_at: "String",
      finish_at: "String",
      organisation_id: "ID",
      organisers: "[ID]",
      trainers: "[ID]",
      participants: "[ID]",
    };
    if (id) {
      data.id = id;
      variables.id = "ID!";
      variables.materials = "[ID]";
    }
    stableClient.mutation('courseTeam', variables, data, 'id').then(result => {
      if (result && result.response) {
        notify(intl.formatMessage({id: "team.edit.saved", defaultMessage: "Team was saved"}), "succes");
        if (isNaN(id)) {
          props.history.replace(props.history.location.pathname.replace("create", result.response.id));
        }
      }
    });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          message={notification.message}
          style={{backgroundColor: notification.severity}}
          open={notification.show}
          onClose={closeNotification}
          autoHideDuration={6000}
        />
        <AppBar position="static" color="inherit" style={{marginBottom: "1rem"}}>
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label={intl.formatMessage({id: "team.edit.tabs.general", defaultMessage: "General"})}
                 icon={<Home/>} {...a11yProps(0)} disabled={isNaN(id)}/>
            <Tab label={intl.formatMessage({id: "team.edit.tabs.days", defaultMessage: "Days"})}
                 icon={<Event/>} {...a11yProps(2)} disabled={isNaN(id)}/>
            <Tab label={intl.formatMessage({id: "team.edit.tabs.evaluation", defaultMessage: "Participants"})}
                 icon={<People/>} {...a11yProps(1)} disabled={isNaN(id)}/>
          </Tabs>
        </AppBar>
        <TabPanel value={tab} index={0}>
          <Grid container>
            <Grid item xs={12}>
              <EditForm
                data={team}
                setData={setTeam}
                isLoading={false}
                save={generalSave}
                validation={validation}
                fields={generalFields}
                colSizes={[8, 4]}
                extraComponent={
                  <Grid container>
                    {id ? <Grid item xs={12}>
                      <MaterialSelectTable
                        materials={team.materials}
                        onAdd={row => setTeam(cur => {
                          return {...cur, materials: [...cur.materials, row]}
                        })}
                        onRemove={row => setTeam(cur => {
                          return {...cur, materials: cur.materials.filter(c => c.id !== row.id)}
                        })}
                      />
                    </Grid> : ''}
                    <Grid item xs={6}>
                      <UserSelectTable
                        users={team.trainers}
                        title={intl.formatMessage({id: "users.list.trainers_heading", defaultMessage: "Trainers"})}
                        role="trainer"
                        onAdd={row => setTeam(cur => {
                          return {...cur, trainers: [...cur.trainers, row]}
                        })}
                        onRemove={row => setTeam(cur => {
                          return {...cur, trainers: cur.trainers.filter(u => u.id !== row.id)}
                        })}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <UserSelectTable
                        users={team.organisers}
                        title={intl.formatMessage({id: "users.list.organisers_heading", defaultMessage: "Organisers"})}
                        role="organisers"
                        onAdd={row => setTeam(cur => {
                          return {...cur, organisers: [...cur.organisers, row]}
                        })}
                        onRemove={row => setTeam(cur => {
                          return {...cur, organisers: cur.organisers.filter(u => u.id !== row.id)}
                        })}
                      />
                    </Grid>
                  </Grid>
                }
                history={props.history}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <DayTable
            days={team.course_team_days}
            history={props.history}
            notify={notify}
            onUpdate={(data) => setTeam({
              ...team,
              course_team_days: team.course_team_days.map(d => d.id === data.id ? data : d)
            })}
          />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <UserSelectTable
            users={team.participants}
            title={intl.formatMessage({id: "users.list.participants_heading", defaultMessage: "Participants"})}
            role="participant"
            onAdd={row => setTeam(cur => {
              return {...cur, participants: [...cur.participants, row]}
            })}
            onRemove={row => setTeam(cur => {
              return {...cur, participants: cur.participants.filter(u => u.id !== row.id)}
            })}
          />
          <Grid
            container
            spacing={2}
            justifyContent={"flex-end"}
            style={{marginTop: "20px"}}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={() => generalSave(team)}
            >
              <FormattedMessage id={"common.button.save"}/>
            </Button>
          </Grid>
        </TabPanel>
      </Grid>
    </Grid>
  );
}
