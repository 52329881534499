import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
// import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
// import Typography from "@material-ui/core/Typography";

import componentStyles from "assets/theme/views/admin/dashboard.js";

const useStyles = makeStyles(componentStyles);

export default function Dashboard() {
  const classes = useStyles();
  const theme = useTheme();
  
  return (
    <Container
      maxWidth={false}
      component={Box}
      classes={{ root: classes.containerRoot }}
    >
      <Grid container>
        <Grid item xs={12} xl={4}>
          <Card classes={{ root: classes.cardRoot }}>
            <CardHeader
              title={
                <Box component="span" color={theme.palette.gray[600]}>
                  Welcome To
                </Box>
              }
              subheader="Relatuz"
              classes={{ root: classes.cardHeaderRoot }}
              titleTypographyProps={{
                component: Box,
                variant: "h6",
                letterSpacing: ".0625rem",
                marginBottom: ".25rem!important",
                classes: {
                  root: classes.textUppercase,
                },
              }}
              subheaderTypographyProps={{
                component: Box,
                variant: "h2",
                marginBottom: "0!important",
                color: "initial",
              }}
            ></CardHeader>
            {/* <CardContent>
              <Box position="relative" height="200px">
                <Typography variant="h1">Welcome to Relatuz</Typography>
              </Box>
            </CardContent> */}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}