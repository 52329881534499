import React, {useCallback, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {Card, CardContent, CardHeader, Grid, Snackbar, Typography} from "@material-ui/core";
import {graphQLApi, graphQLReduceFields} from "../../../services/GraphQLApi";
import {useAuthDispatch} from "../../../contexts/Auth";
import EditForm from "../../../components/Form/EditForm";
import LevelElementComponent from "./LevelElementComponent";
import {FormatListNumbered} from "@material-ui/icons";


export default function CourseLevelEdit(props) {
  const intl = useIntl();

  let id = Number(props.match.params.id);
  /**
   * Notification
   */
  const [notification, setNotification] = React.useState({
    severity: 'info',
    show: false,
    message: '',
  });
  const notify = (message, color = 'info') => {
    setNotification({severity: color, message: message, show: true});
  }
  const closeNotification = () => setNotification({...notification, show: false});


  const generalFields = [
    {
      column: 1,
      field: "title",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "course_levels.edit.label.title", defaultMessage: "Title"}),
      input: "text"
    },
    {
      column: 2,
      field: "level",
      initial: "",
      type: "Int",
      label: intl.formatMessage({id: "course_levels.edit.label.level", defaultMessage: "Level"}),
      input: "number"
    },
    {
      field: "description",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "course_levels.edit.label.description", defaultMessage: "Description"}),
      input: "html",
      fullWidth: true,
    }
  ];
  const defaultElements = [
    {title: "", link: "", sorting: 1, image: "", image_data: null},
    {title: "", link: "", sorting: 2, image: "", image_data: null},
    {title: "", link: "", sorting: 3, image: "", image_data: null},
    {title: "", link: "", sorting: 4, image: "", image_data: null},
    {title: "", link: "", sorting: 5, image: "", image_data: null},
    {title: "", link: "", sorting: 6, image: "", image_data: null},
    {title: "", link: "", sorting: 7, image: "", image_data: null},
    {title: "", link: "", sorting: 8, image: "", image_data: null},
  ];
  const [courseLevel, setCourseLevel] = useState({...graphQLReduceFields(generalFields, 'initial'), course_level_elements:defaultElements});
  const generalSave = (data) => {
    let variables = {
      title: "String",
      description: "String",
      level: "Int",
      course_level_elements: "[CourseLevelElementInput]"
    };
    let d = {...data};
    d.course_level_elements = d.course_level_elements
      .filter(e => String(e.title).length > 0)
      .map(e => ({id:e.id, title:e.title, link:e.link, sorting:e.sorting, image:e.image, image_data:e.image_data}));
    if (id) {
      d.id = id;
      variables.id = "ID!";
    }
    client.mutation('courseLevel', variables, d, 'id').then(result => {
      if (result && result.response) {
        notify(intl.formatMessage({
          id: "course_levels.edit.saved",
          defaultMessage: "Course level was saved"
        }), "succes");
        if (isNaN(id)) {
          props.history.replace(props.history.location.pathname.replace("create", result.response.id));
        }
      }
    });
  }

  const client = new graphQLApi(useAuthDispatch(), props.history);
  const stableClient = useCallback(client, []);

  useEffect(() => {
    if (id) {
      stableClient.query('{' +
        'courseLevels(filter:{id:' + id + '}) {data{' +
        ' id title level description course_level_elements {id title link sorting image image_uri}' +
        '}}}')
        .then(response => {
          if (response) {
            if (response.hasOwnProperty('courseLevels')) {
              let lvl = response.courseLevels.data[0];
              if (lvl.course_level_elements.length === 0) {
                lvl.course_level_elements = defaultElements;
              }
              lvl.course_level_elements = defaultElements.map(e => {
                let elm = lvl.course_level_elements.find(dbElm => dbElm.sorting === e.sorting);
                if (elm) return elm;
                return e;
              })
              setCourseLevel(lvl);
              if (lvl.description !== null) {
                let descField = generalFields.find(f => f.field === 'description');
                if (descField && descField.editor) {
                  descField.editor.setData(lvl.description);
                }
              }
            }
          }
        });
    }
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          message={notification.message}
          style={{backgroundColor: notification.severity}}
          open={notification.show}
          onClose={closeNotification}
          autoHideDuration={6000}
        />
        <Card>
          <CardHeader
            avatar={<FormatListNumbered color="primary"/>}
            title={id ?
              intl.formatMessage({id: "course_levels.heading.edit", defaultMessage: "Edit course level"}) :
              intl.formatMessage({id: "course_levels.heading.create", defaultMessage: "Create course level"})
            }
            titleTypographyProps={{color:"primary"}}
          />
          <CardContent>
            <EditForm
              data={courseLevel}
              setData={setCourseLevel}
              isLoading={false}
              save={generalSave}
              validation={graphQLReduceFields(generalFields, 'validation')}
              fields={generalFields}
              colSizes={[8,4]}
              extraComponent={
                <Grid container spacing={2}>
                  <Grid item xs={12}><Typography variant="h2">
                    {intl.formatMessage({id:"course_levels.elements.heading", defaultMessage:"Dashboard elements for this course level"})}
                  </Typography><hr/></Grid>
                  {courseLevel.course_level_elements && courseLevel.course_level_elements.map((elm, key) =>
                    <Grid
                      key={"course-level-element-" + key}
                      item
                      xs={3}
                    >
                      <LevelElementComponent
                        element={elm}
                        setElement={element => setCourseLevel(cur => {
                          let elements = [...cur.course_level_elements];
                          elements[key] = element;
                          return {
                            ...cur,
                            course_level_elements: elements
                          }
                        })}
                      />
                    </Grid>
                  )}
                </Grid>
              }
              history={props.history}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
