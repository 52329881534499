import React from "react";
import {useIntl} from "react-intl";
import {Grid, Card, CardHeader, CardContent} from "@material-ui/core";
import {GroupWork} from "@material-ui/icons";
import GraphQLEditForm from "components/GraphQL/GraphQLEditForm";

export default function ImplementationActivityEdit(props) {
    const intl = useIntl();

    let id = Number(props.match.params.id);

    const fields = [
        {
            field: "title",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "implementation_activities.edit.label.title", defaultMessage: "Title"}),
            input: "text"
        },
        {
            field: "description",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "implementation_activities.edit.label.description", defaultMessage: "Description"}),
            input: "html"
        },
        {
            field: "sorting",
            initial: 1,
            type: "Int",
            label: intl.formatMessage({id: "implementation_activities.edit.label.sorting", defaultMessage: "Sorting"}),
            input: "number",
            inputProps: {
                min: 1,
                max: 255,
                step: 1,
            }
        },
        {
            field: "file",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "implementation_activities.edit.label.file", defaultMessage: "Schema file"}),
            input: "file",
            accepts: "*",
        }
    ];

    return (
        <Grid container>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        avatar={<GroupWork color="primary"/>}
                        title={intl.formatMessage({id: "implementation_activities.edit.heading", defaultMessage:"Implementation Activities edit"})}
                        titleTypographyProps={{color:"primary"}}
                    />
                    <CardContent>
                        <GraphQLEditForm id={id}
                                         query={"implementationActivities"}
                                         mutations={"implementationActivity"}
                                         fields={fields}
                                         cols={1}
                                         {...props}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
