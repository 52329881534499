import React from "react";
import {Paper} from "@material-ui/core";
import EnhancedTable from "components/DataTable/EnhancedTable";
import {MenuBook} from "@material-ui/icons";
import {useIntl} from "react-intl";

export default function ConceptList(props) {
    const intl = useIntl();
    const columns = [
        {title: intl.formatMessage({id:'concepts.list.column.name', defaultMessage:'Name'}), field: 'name'},
        {title: intl.formatMessage({id:'concepts.list.column.description', defaultMessage:'Description'}), field: 'description',
            render: row => {
                if (row.description) {
                    let doc = new DOMParser().parseFromString(row.description, 'text/html');
                    return doc.body.textContent.slice(0, 50) + '...' || "";
                }
                return "";
            }
        },
        {title: intl.formatMessage({id:'concepts.list.column.file', defaultMessage:'File'}), field: 'file'},
        {title: intl.formatMessage({id:'concepts.list.column.link', defaultMessage:'Link'}), field: 'link'}
    ];

    return (
      <Paper>
        <EnhancedTable
          title={intl.formatMessage({id:'concepts.list.table.heading', defaultMessage:"Concepts list"})}
          columns={columns}
          query="concepts"
          mutations="concept"
          fields='id name description file link'
          icon={<MenuBook/>}
          {...props}
        />
      </Paper>
    );
}