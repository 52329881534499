import React from "react";
import {useIntl} from "react-intl";
import {Card, CardHeader, CardContent, Grid} from "@material-ui/core";
import {Language} from "@material-ui/icons";
import GraphQLEditForm from "components/GraphQL/GraphQLEditForm";

export default function LanguageEdit(props) {
    const intl = useIntl();

    let id = Number(props.match.params.id);

    const fields = [
        {
            field: "name",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "languages.edit.label.name", defaultMessage: "Name"}),
            input: "text"
        },
        {
            field: "country_code",
            initial: "",
            type: "String",
            label: intl.formatMessage({
                id: "languages.edit.label.country_code",
                defaultMessage: "Country code (DK, UK, US, DE, etc.)"
            }),
            input: "country",
            inputCode: "Alpha-2"
        },
        {
            field: "locale",
            initial: "",
            type: "String",
            label: intl.formatMessage({
                id: "languages.edit.label.locale",
                defaultMessage: "Locale (da, de, en, es, se, etc.)"
            }),
            input: "country",
            inputCode: "Alpha-2"
        }
    ];

    return (
        <Grid container>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        avatar={<Language color="primary"/>}
                        title={intl.formatMessage({id: "languages.edit.heading"})}
                        titleTypographyProps={{color:"primary"}}
                    />
                    <CardContent>
                        <GraphQLEditForm
                            id={id}
                            query={"languages"}
                            mutations={"language"}
                            fields={fields}
                            cols={2}
                            {...props}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}